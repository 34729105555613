import Axios from 'axios';
import {
  debuggerLog,
  debuggerLogError,
} from '../utils/debuggerLog';
import { CbStatusService, CB_STATUS } from './CbStatusService';
import { ApiUrlService } from './ApiUrlService';

export const TYPE_HTTPS = Object.freeze({
  GET: 'get',
  POST: 'post',
});

export class HttpsService {
  static instance;
  cbStatus = new CbStatusService();
  apiUrlService = new ApiUrlService();
  api = Axios.create({ baseURL: this.apiUrlService.getApiUrl() });
  timeoutLimit = 5000;
  apiError = [];
  personalizationMode = JSON.parse(
    localStorage.getItem('personalizationMode') || 'true'
  );
  maxRetries = 3;
  retryDelay = 1000;
  requestQueue = [];
  processingQueue = false;
  queueDelay = 500;
  queuedEvents = new Set();

  constructor() {
    return HttpsService.instance || (HttpsService.instance = this);
  }

  get getApiBaseURL() {
    return this.api.defaults.baseURL;
  }

  personalizationModeOff() {
    if (this.personalizationMode) return;
    this.cbStatus.sendApiResponseToBar(CB_STATUS.API_Off);
  }

  apiSuccess() {
    if (this.apiError.length || !this.personalizationMode) return;
    this.cbStatus.sendApiResponseToBar(CB_STATUS.SUCCESS);
  }

  async https({ type, url, payload, config = {}, fullUrl, retries = 0, skipRetry = false }) {
    debuggerLog('[HTTP]: HttpsService', { HttpsService: this });
    this.personalizationModeOff();

    let httpsTimeControl;
    try {
      httpsTimeControl = setTimeout(() => {
        if (this.apiError.length || !this.personalizationMode) return;
        this.cbStatus.sendApiResponseToBar(CB_STATUS.TIME_USED_UP);
      }, this.timeoutLimit);

      let result = {};
      let response;
      if (!fullUrl) {
        response = await this.api[type](url, payload, config);
      } else {
        response = await Axios[type](fullUrl, payload, config);
      }

      clearTimeout(httpsTimeControl);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(`HTTP Error: Received status code ${response.status}`);
      }

      const { data } = response;
      result = data;

      if (!data || Object.keys(data).length === 0) {
        debuggerLog(`Empty response received for ${url || fullUrl}`);
      }

      this.apiSuccess();

      return { ok: true, data: result, is_encrypted: !window.finDebug };
    } catch (error) {
      clearTimeout(httpsTimeControl);
      if (error.response) {
        debuggerLogError(`[HTTP]: Request failed with status code ${error.response.status}`, error.response);
      } else if (error.request) {
        debuggerLogError('[HTTP]: No response received (network issue or blocked request)', error.request);
      } else {
        debuggerLogError('[HTTP]: Error during request setup or execution', error.message);
      }

      if (!skipRetry && retries < this.maxRetries) {
        debuggerLog(`[HTTP]: Retrying ${url || fullUrl}... Attempt ${retries + 1}`);
        await this.delay(this.retryDelay);
        return this.https({ type, url, payload, config, fullUrl, retries: retries + 1, skipRetry });
      }
      this.apiError.push({ url });
      this.cbStatus.sendApiResponseToBar(CB_STATUS.ERROR);
      return { ok: false, error };
    }
  }

  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  addToQueue(request) {
    const requestIdentifier = JSON.stringify(request.payload.payload.data);
    // Skip adding the duplicate request
    if (this.queuedEvents.has(requestIdentifier)) {
      debuggerLog(`[HTTP]: Request already in queue: ${requestIdentifier}`);
      return;
    }

    this.queuedEvents.add(requestIdentifier);
    this.requestQueue.push(request);
    if (!this.processingQueue) {
      this.processQueue();
    }
  }

  async processQueue() {
    this.processingQueue = true;
    while (this.requestQueue.length > 0) {
      const request = this.requestQueue.shift();
      await this.https(request);
      await this.delay(this.queueDelay);
    }
    this.processingQueue = false;
    this.queuedEvents.clear();
  }
}
