import {
  debuggerLog,
  debuggerLogError,
} from '../utils/debuggerLog';

export class DocumentBodyService {
  static instance;
  constructor() {
    return (
      DocumentBodyService.instance || (DocumentBodyService.instance = this)
    );
  }

  async showElement(element) {
    element.style.animation = 'unset';
    element.style.opacity = '1';
    element.style.visibility = 'visible';
  }

  async showBody() {
    debuggerLog('[bodyVisible][showBody]');
    const body = window.document.getElementsByTagName('body');
    if (body.length > 0) {
      this.showElement(body[0]);
    }
  //  window.document.body.style.animation = 'unset';
  //  window.document.body.style.opacity = '1';
  //  window.document.body.style.visibility = 'visible';
  }

  async showId(thisId) {
    debuggerLog('[bodyVisible][showId]', thisId);
    const thisid = window.document.getElementById(thisId);
    if (thisid) {
      this.showElement(thisid);
    //  thisid.style.animation = 'unset';
    //  thisid.style.opacity = '1';
    //  thisid.style.visibility = 'visible';
    }
  }

  async showClass(className) {
    debuggerLog('[bodyVisible][showClass]', className);
    const classes = window.document.getElementsByClassName(className);
    for (let i = 0; i < classes.length; i++) {
      debuggerLog('[bodyVisible] class', classes[i]);
      this.showElement(classes[i]);
    //  classes[i].style.animation = 'unset';
    //  classes[i].style.opacity = '1';
    //  classes[i].style.visibility = 'visible';
    }
  }

  bodyVisible() {
    //debuggerLog('[bodyVisible]', localStorage.getItem('suppress_body'));
    //let show_divs = localStorage.getItem('suppress_body');
    //let cu_code = window.getversion.payload.code;
    let cu_code = window.code;
    //if (show_divs === 'active') {
    if (window.suppress_body === false) {
      debuggerLog('[bodyVisible] cu_code', cu_code);
      if (window.divObj) {
        const divs = window.divObj;
        const divIds = divs['ids'];
        const divClasses = divs['classes'];
        if (divIds) {
          for (const divId of divIds) {
            if (divId) {
              this.showId(divId);
            }
          }
        }
        if (divClasses) {
          for (const divClass of divClasses) {
            if (divClass) {
              this.showId(divClass);
            }
          }
        }
      }
      //this.showBody();
    //  window.document.body.style.animation = 'unset';
    //  window.document.body.style.opacity = 1;
    //  window.document.body.style.visibility = 'visible';
    //  Object.assign(window.document.body.style, {
    //    animation: 'unset',
    //    opacity: 1,
    //    visibility: 'visible',
    //  });
    } else {
      this.showBody();
    //  window.document.body.style.animation = 'unset';
    //  window.document.body.style.opacity = 1;
    //  window.document.body.style.visibility = 'visible';
    //  Object.assign(window.document.body.style, {
    //    animation: 'unset',
    //    opacity: 1,
    //    visibility: 'visible',
    //  });
    }
  }
}
