import { debuggerLog } from '../utils/debuggerLog';

export const CB_STATUS = Object.freeze({
  ERROR: 'error500',
  SUCCESS: 'success',
  TIME_USED_UP: 'timeUsedUp',
  V1_ERROR: 'v1Error',
  API_Off: 'apiOff',
});

export class CbStatusService {
  static instance;

  constructor() {
    return CbStatusService.instance || (CbStatusService.instance = this);
  }

  sendApiResponseToBar(val) {
    debuggerLog('sendApiResponseToBar', val);
    if (window.onApiResponse) {
      debuggerLog('sendApiResponseToBar', val);
      window.onApiResponse(val);
    }
  }
}
