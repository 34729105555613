import FinalyticsPixel from './models/FinalyticsPixel';
import { debuggerLog } from './utils/debuggerLog';

const init = () => {
  debuggerLog('[Finalytics Pixel] Initializing v42');
  window.finalytics = new FinalyticsPixel(window.finalytics);
  debuggerLog('[Finalytics Pixel] Ready');

  window.finalytics.init(
    window.finalytics.clientId
    || new URLSearchParams(window.location.search).get('cu_id')
    || '',
  );

};

init();
