const buildQueryParams = (obj) => Object.entries(obj)
  .filter(([, value]) => {
    try {
      if (Array.isArray(value)) return value.length;
      if (!Object.keys(value).length) return false;
      return value;
    } catch (e) {
      return value;
    }
  })
  .map((entry) => {
    const [key, value] = entry;
    if (['string', 'number'].includes(typeof value)) return [key, value].join('=');
    return [key, JSON.stringify(value)].join('=');
  }).join('&');

export {
  buildQueryParams,
};
