/* eslint-disable no-console */
import { debuggerLog } from '../utils/debuggerLog';
import { CacheStorageService, CACHES_KEY } from './CacheStorageService';

export const SUPRESS_CONTROL = Object.freeze({
  CLASSES: 'classes',
  IDS: 'ids',
});

export class SuppressBodyService {
  static instance;
  data = '';
  location = window.location.pathname || '';
  hidden = 'visibility: hidden; opacity: 0;';
  visible = 'visibility: visible; opacity: 1;animation: unset;';
  cacheStorageService = new CacheStorageService();
  pathElements = {
    ids: [],
    classes: [],
  };
  cachesUrl = localStorage.getItem('getVersion');
  allElements = [];

  constructor() {
    return (
      SuppressBodyService.instance || (SuppressBodyService.instance = this)
    );
  }

  async showElementsAll(a) {
    debuggerLog('showElementsAll', { this: this, a });
    await this.normalizeIdsAndClass();
    if (this.pathElements[SUPRESS_CONTROL.IDS]) {
      this.supressElement(
        this.pathElements[SUPRESS_CONTROL.IDS],
        this.visible
      );
    }
    if (this.pathElements[SUPRESS_CONTROL.CLASSES]) {
      this.supressElement(
        this.pathElements[SUPRESS_CONTROL.CLASSES],
        this.visible
      );
    }
  //  this.supressElement(
  //    [
  //      ...this.pathElements[SUPRESS_CONTROL.IDS],
  //      ...this.pathElements[SUPRESS_CONTROL.CLASSES],
  //    ],
  //    this.visible
  //  );
  }

  async showElements(ads) {
    debuggerLog('showElements input', { ads, this: this });
    await this.normalizeIdsAndClass();
    if (this.pathElements[SUPRESS_CONTROL.IDS]) {
      this.supressElement(
        this.pathElements[SUPRESS_CONTROL.IDS].filter((element) => !ads.includes(element) && element),
        this.visible
      );
    }
    if (this.pathElements[SUPRESS_CONTROL.CLASSES]) {
      this.supressElement(
        this.pathElements[SUPRESS_CONTROL.CLASSES].filter((element) => !ads.includes(element) && element),
        this.visible
      );
    }
  //  this.supressElement(
  //    [
  //      ...this.pathElements[SUPRESS_CONTROL.IDS],
  //      ...this.pathElements[SUPRESS_CONTROL.CLASSES],
  //    ].filter((element) => !ads.includes(element) && element),
  //    this.visible
  //  );
  }

  supressElement(elements, styles) {
    debuggerLog('supressElement input', { elements, styles, this: this });
    elements.forEach(
      (element) =>
        document.querySelector(element) && (document.querySelector(element).style.cssText = document.querySelector(element).style.cssText + styles)
        //document.querySelector(element) && Object.assign(document.querySelector(element).style, {
        //  //animation: 'unset',
        //  opacity: 1,
        //  visibility: 'visible',
        //})
    );
  }

  hideElements() {
    debuggerLog('normalizeIdsAndClass hideElements', this);
    this.supressElement(
      [
        ...this.pathElements[SUPRESS_CONTROL.IDS],
        ...this.pathElements[SUPRESS_CONTROL.CLASSES],
      ],
      this.hidden
    );
  }

  validationSuppress() {
    //let show_divs = localStorage.getItem('suppress_body');
    //return show_divs !== 'active';
    return window.suppress_body;
  }

  async normalizeIdsAndClass() {
    if (this.validationSuppress()) return;
    const caches = await this.cacheStorageService.getCaches(
      CACHES_KEY.GETVERSION,
      this.cachesUrl
    );
    debuggerLog('normalizeIdsAndClass result', caches);
    
    
    this.data = caches?.pages || [];
  
    if (this.data.length === 0) {
      debuggerLog('normalizeIdsAndClass: data is empty, cannot process elements');
      this.pathElements = {};
      return;
    }
  
    new Map(Object.entries(this.data[0])).forEach((value, key) => {
      if (!('ids' in value && 'classes' in value)) return;
  
      const ids = value.ids.filter(Boolean).map((element) => `#${element}`),
        classes = value.classes.filter(Boolean).map((element) => `.${element}`);
      Object.assign(this.data[0], {
        [key]: {
          ids,
          classes,
        },
      });

      this.allElements = [...this.allElements, ...ids, ...classes];
    });

    if (!this.data[0][this.location]) {
      this.pathElements = {};
    } else {
      this.pathElements = this.data[0][this.location];
    }

    debuggerLog('normalizeIdsAndClass result', this);
  } 

  async waitToSuppress() {
    this.supressElement(this.allElements, this.visible);
    //this.supressElement(this.allElements, this.hidden);
  }

  async init() {
    if (this.validationSuppress()) return;
    await this.normalizeIdsAndClass();
    if (!this.data[0] || !Object.keys(this.data[0]).includes(this.location)) {
      debuggerLog('supressElement init if', this);
      //this.waitToSuppress();
      //window.document.addEventListener('DOMContentLoaded', this.waitToSuppress());
      let counter = 0;
      const timer = setInterval(() => {
        if (window.document.readyState === 'interactive' || window.document.readyState === 'complete') {
          clearInterval(timer);
          this.waitToSuppress();
          //resolve();
        } else if (counter === 60) {
          clearInterval(timer);
          //reject();
        }
        counter++;
      }, 50);
      return;
    }
    //this.hideElements();
    debuggerLog('supressElement init', this);
  }

//  async init() {
//    if (this.validationSuppress()) return;
//    await this.normalizeIdsAndClass();
//    if (!Object.keys(this.data[0]).includes(this.location)) {
//      console.log('supressElement init if', this);
//      setTimeout(
//        () => this.supressElement(this.allElements, this.visible),
//        1000 //1000
//      );
//      return;
//    }
//    console.log('supressElement init', this);
//    this.hideElements();
//  }
}
