import { debuggerLog } from '../utils/debuggerLog';
import { FORAGE_KEY, LocalForageService } from './LocalForageService';

export const CONTROL_SS_KEY = Object.freeze({
  ADS_VIEWED: 'ads_viewed',
  DEMOGRAPHICS: 'demographics',
  ID: 'id',
  LAST: 'last',
  LOCATIONS: 'locations',
  VIEWS: 'views',
  VISITS: 'visits',
});

export class ControlSessionService {
  static instance;
  controlSessionSave = [
    'ads_viewed',
    'demographics',
    'id',
    'last',
    'locations',
    'views',
    'visits',
  ];
  controlSessionValues = {};
  minute = 60000;

  constructor() {
    this.localForageService = new LocalForageService();
    return (
      ControlSessionService.instance || (ControlSessionService.instance = this)
    );
  }
  
  set setControlSessionSave(data) {
    this.controlSessionSave.push(data);
  }

  get getAllControlSessionValues() {
    this.getControlSessionValues();
    return this.controlSessionValues;
  }

  updateViewsCategory() {
    const newViews = [],
      olbViews = this.controlSessionValues.views;
    if (!olbViews || !olbViews.length) return newViews;
    window.finalytics &&
      olbViews.length &&
      olbViews.forEach(
        (olb) =>
          window.finalytics.pages[Object.keys(window.finalytics.pages)][
            olb.url
          ] &&
          newViews.push({
            ...olb,
            ...window.finalytics.pages[Object.keys(window.finalytics.pages)][
              olb.url
            ],
          })
      );
    return newViews;
  }
  
  getControlSessionValues(key) {
    if (!Object.keys(this.controlSessionValues).length) {
      this.controlSessionValues = this.controlSessionSave.reduce(
        (acc, element) => {
          const value = JSON.parse(localStorage.getItem(element) || 'false');
          return {
            ...acc,
            [element]: value,
          };
        },
        {}
      );
      this.removeControlSessionValues();
    }
    return this.controlSessionValues[key];
  }

  removeControlSessionValues() {
    debuggerLog(
      '[ControlSessionService][removeControlSessionValues] values',
      this.controlSessionValues
    );
    this.controlSessionSave.forEach((element) =>
      localStorage.removeItem(element)
    );
  }

  async setControlSessionValues() {
    const sessionData = await this.localForageService.getForage(
      FORAGE_KEY.SESSION_DATA
    );

    Object.entries(sessionData)
      .filter(
        (element) => this.controlSessionSave.includes(element[0]) && element
      )
      .forEach((element) =>
        localStorage.setItem(element[0], JSON.stringify(element[1] || {}))
      );
  }

  async restartSession() {
    await this.setControlSessionValues();
    //window.onClearStorage();
  }

  controlSession() {
    setInterval(() => {
      //if (Date.now() >= (+localStorage.getItem('timestampDefaultValue') || window.timestamp) + 1800000) this.restartSession();
    }, this.minute);
  }
}
