import { debuggerLog } from './debuggerLog';

const scoring = (sessionData) => {
  debuggerLog('[scoring][input]', sessionData);
  if([0,1].includes(Object.keys(sessionData).length)) return;
  const viewObj = {};
  const halfLife = 60000 * 60 * 24 * 30;
    
  const currentTime = Date.now();

  sessionData.views.forEach((view) => {
    const weight = 1 - (currentTime - view.timestamp) / (2 * halfLife);
    const score = weight > 1 ? 1 : weight < 0 ? 0 : weight;
    if (!viewObj[view.category] && view.category) {
      viewObj[view.category] = score;
    } else if (view.category) {
      viewObj[view.category] += score;
    }
  });
  return Object.entries(viewObj).map(([key, value]) => ({ [key]: value.toFixed(2) }));

};

export {
  scoring,
};
