import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse('qG~\'$nt8@[B-pEY3');

const salt = 'qG~\'$nt8@[B-pEY3';

function encrypt(object) {
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(object), key, { iv });

  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

function decrypt(ciphertextStr) {
  const ciphertext = CryptoJS.enc.Base64.parse(ciphertextStr);

  // split IV and ciphertext
  const iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16;

  // decryption
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext },
    key,
    { iv },
  );

  const objString = decrypted.toString(CryptoJS.enc.Utf8);
  const obj = JSON.parse(objString);

  return obj;
}

function textToChars(text) {
  return text.split('').map((c) => c.charCodeAt(0));
}

function byteHex(n) {
  return (`0${Number(n).toString(16)}`).substr(-2);
}

function applySaltToChar(code) {
  // eslint-disable-next-line no-bitwise
  return textToChars(salt).reduce((a, b) => a ^ b, code);
}

function basicEncrypt(text) {
  return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
}

function basicDecrypt(encoded) {
  return encoded.match(/.{1,2}/g).map((hex) => parseInt(hex, 16)).map(applySaltToChar).map((charCode) => String.fromCharCode(charCode)).join('');
}

export {
  encrypt,
  decrypt,
  basicEncrypt,
  basicDecrypt,
};
