/* eslint-disable no-console */
import { personalizeAllPages } from '../utils';
import {
  ApiUrlService,
  API_ENVIRONMENT,
  CacheStorageService,
  CACHES_KEY,
  DocumentBodyService,
  HttpsService,
  SuppressBodyService,
  SUPRESS_CONTROL,
  TYPE_HTTPS
} from './index';
import {
  debuggerLog,
  debuggerLogError,
} from '../utils/debuggerLog';

const STATUS_CACHES = Object.freeze({
  NO_CACHE: 'no_cache',
  CACHE: 'cache',
});

export class ApiDirectoryService {
  cacheStorageService = new CacheStorageService();
  documentBodyService = new DocumentBodyService();
  apiUrlService = new ApiUrlService();
  endpoint = '';
  cu_code = '';
  getversion = {
    payload: {
      personalization_is_active: false,
      personalize_all_pages: false,
      version: null,
    },
  };

  constructor(code) {
    this.code = code;
  }

  get getGetversion() {
    return this.getversion;
  }

  async suppressBody() {
    const cache = await this.getCache(
      `/getversion/?cu_id=${this.code}&session_init=1`
    );
    //if (cache.payload.suppress_body)
    //  return localStorage.removeItem('suppress_body');
    //localStorage.setItem('suppress_body', SUPRESS_CONTROL.SUPPRESS);
    //this.documentBodyService.bodyVisible();
    await new SuppressBodyService().init();
  }

  setSessionStorage() {
    sessionStorage.setItem(
      'personalizationIsActive',
      JSON.stringify(this.getversion.payload.personalization_is_active)
    );
    sessionStorage.setItem(
      'personalizationVersion',
      JSON.stringify(this.getversion.payload.version)
    );
  }

  async updateCache({ payload: { version } }) {
    if (version === this.getversion.payload.version) return;
    await this.cacheStorageService.clearCaches();
  }

  async setCache(keyValue, data) {
    await this.cacheStorageService.setOneCaches(
      CACHES_KEY.GETVERSION,
      keyValue,
      data
    );
  }

  async getCache(keyValue) {
    return await this.cacheStorageService.getCaches(
      CACHES_KEY.GETVERSION,
      keyValue
    );
  }

  async setEndpoint() {
    const existCache = await this.getCache(
      `/getversion/?cu_id=${this.code}&session_init=1`
    );
    if (!existCache) {
      this.endpoint = `getversion/?cu_id=${this.code}&session_init=1`;
      return STATUS_CACHES.NO_CACHE;
    }

    this.endpoint = `getversion/?cu_id=${this.code}&session_init=1`;
    this.getversion = existCache;
    return STATUS_CACHES.CACHE;
  }

  urlVersion() {
    const queryParamApi = sessionStorage.getItem('api');
    const customerApi = this.apiUrlService.customerEnvironment();
    return customerApi
      ? `${customerApi}/${this.endpoint}`
      : `${
        API_ENVIRONMENT[`${queryParamApi}Q`] ||
        API_ENVIRONMENT[`${window.hostEnv || 'prod'}Q`]
      }/${this.endpoint}`;
  }

  async httpsVersion() {
    const ts_gv = Date.now();
    debuggerLog('ts: getversion call start', Date.now() - window.ts_start);
    let count = 0;
    localStorage.setItem(
      'getVersion',
      `/getversion/?cu_id=${this.code}&session_init=1`
    );
    const result = await this.setEndpoint();
    if (result === STATUS_CACHES.NO_CACHE) {
      window.firstPageView = true;
      debuggerLog('ts: getversion firstPageView EVER', window.firstPageView);
    }
    return new Promise((resolve, reject) => {
      const timer = setInterval(async () => {
        let gvFlag = false;
        if (window.finalytics) {
          gvFlag = true;
          if (window.getVersionInSettings === true) {
            gvFlag = (window.getVersionInSettings === true && window.getversion2);
          } else if (window.getVersionInSettings === undefined) {
            gvFlag = false;
          }
        }
        debuggerLog('ts: gvFlag', gvFlag);
        if (gvFlag) {
          clearInterval(timer);
          debuggerLog('ts: getversion injectsettings getversioninsettings', window.injectSettings, window.getVersionInSettings);
          try {
            debuggerLog('ts: getversion api or settings start ms elapsed', Date.now() - ts_gv);
            let data = {};
            if (window.getVersionInSettings === true) {
              debuggerLog('ts: getversion settings.js only start ms elapsed', Date.now() - ts_gv);
              data = JSON.parse(window.getversion2);
            } else {
              const api = new HttpsService();
              debuggerLog('ts: getversion api only start ms elapsed', Date.now() - ts_gv);
              data = await api.https({
                type: TYPE_HTTPS.GET,
                fullUrl: this.urlVersion(),
              });
              debuggerLog('ts: getversion api only end ms elapsed', Date.now() - ts_gv);
            }
            debuggerLog('ts: getversion data obtained', Date.now() - ts_gv);
            debuggerLog('ts: getversion data obtained', data);
            if ('data' in data) {
              data = data.data;
            }
            window.suppress_body = data.payload.suppress_body;
            window.injectSettings = data.payload.inject_settings;
            window.getVersionInSettings = data.payload.getversion_in_settings;
            window.firstVisitEnabled = data.payload.first_visit_enabled;
            debuggerLog('ts: getversion suppressbody injectsettings getversioninsettings firstvisitenabled', window.suppress_body, window.injectSettings, window.getVersionInSettings, window.firstVisitEnabled);
            if (window.firstPageView && window.firstVisitEnabled === false) {
              window.skipAdsReplacement = true;
              window.finishedAdsReplacement = true;
              debuggerLog('ts: getversion firstPageView finishedAds', window.skipAdsReplacement);
            }
            debuggerLog('ts: getversion api end ms elapsed', Date.now() - ts_gv);
            debuggerLog('ts: getversion data', data);

            debuggerLog('ts: getversion process data start ms elapsed', Date.now() - ts_gv);
            if (result === STATUS_CACHES.NO_CACHE) {
              window.getversion = data;
              this.getversion = data;
              await this.setCache(
                `/getversion/?cu_id=${this.code}&session_init=1`,
                data
              );
              personalizeAllPages(
                data.pages,
                data.payload.personalize_all_pages
              );
              this.setSessionStorage();
            } else if (result === STATUS_CACHES.CACHE) {
              await this.updateCache(data);
              window.getversion = this.getversion;
              personalizeAllPages(
                this.getversion.pages,
                this.getversion.payload.personalize_all_pages
              );
              this.setSessionStorage();
            }
            debuggerLog('ts: getversion isArray data.pages', data.pages);
            const urlPath = window.location.pathname || '';
            const allPages = data.payload.personalize_all_pages;
            if (window.suppress_body === true) {
              //console.log('ts: getversion set finshedAdsRep to true', data.pages);
              //console.log('ts: getversion set finshedAdsRep to true', urlPath);
              if ((!data.pages.includes(urlPath) && allPages === false)) {
                debuggerLog('ts: getversion set finshedAdsRep to true', Date.now() - ts_gv);
                window.finishedAdsReplacement = true;
              }
            } else {
              // if (data.pages.length > 0) {
              //   if (urlPath in data.pages[0]) {
              //     window.divObj = data.pages[0][urlPath];
              //   }
              // }
            }
            debuggerLog('ts: getversion process data end ms elapsed', Date.now() - ts_gv);
            debuggerLog('ts: getversion suppress body start ms elapsed', Date.now() - ts_gv);
            this.suppressBody();
            debuggerLog('ts: getversion suppress body end ms elapsed', Date.now() - ts_gv);
            resolve();
          } catch (error) {
            debuggerLog('Finalytics Error: ApiDirectoryService', error);
            this.documentBodyService.bodyVisible();
            window.getversion = {
              version:
                  +localStorage.getItem('timestampDefaultValue') || Date.now(),
              personalizationIsActive: false,
            };
            reject();
          }
        } else if (count === 60) {
          clearInterval(timer);
          reject();
        }
        ++count;
      }, 50);
    });
    debuggerLog('ts: getversion call end', Date.now() - window.ts_start);
  }
}
