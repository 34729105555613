/* eslint-disable no-console */
import { personalizeAllPages } from '../utils';
import {
  ApiUrlService,
  API_ENVIRONMENT,
  CacheStorageService,
  CACHES_KEY,
  DocumentBodyService,
  HttpsService,
  SuppressBodyService,
  SUPRESS_CONTROL,
  TYPE_HTTPS
} from './index';
import {
  debuggerLog,
  debuggerLogError,
} from '../utils/debuggerLog';

const STATUS_CACHES = Object.freeze({
  NO_CACHE: 'no_cache',
  CACHE: 'cache',
});

export class ApiDirectoryService {
  cacheStorageService = new CacheStorageService();
  documentBodyService = new DocumentBodyService();
  apiUrlService = new ApiUrlService();
  endpoint = '';
  cu_code = '';
  getversion = {
    payload: {
      personalization_is_active: false,
      personalize_all_pages: false,
      version: null,
    },
  };

  constructor(code) {
    this.code = code;
  }

  get getGetversion() {
    return this.getversion;
  }

  async suppressBody() {
    const cache = await this.getCache(
      `/getversion/?cu_id=${this.code}&session_init=1`
    );
    //if (cache.payload.suppress_body)
    //  return localStorage.removeItem('suppress_body');
    //localStorage.setItem('suppress_body', SUPRESS_CONTROL.SUPPRESS);
    //this.documentBodyService.bodyVisible();
    await new SuppressBodyService().init();
  }

  setSessionStorage() {
    sessionStorage.setItem(
      'personalizationIsActive',
      JSON.stringify(this.getversion.payload.personalization_is_active)
    );
    sessionStorage.setItem(
      'personalizationVersion',
      JSON.stringify(this.getversion.payload.version)
    );
  }

  async updateCache(data) {
    const newVersion = data.payload.version;
    if (newVersion === this.getversion.payload.version) return;
    await this.cacheStorageService.clearCaches();
    await this.setCache(
      `/getversion/?cu_id=${this.code}&session_init=1`,
      data
    );
    this.getversion = data;
  }
  
  async setCache(keyValue, data) {
    await this.cacheStorageService.setOneCaches(
      CACHES_KEY.GETVERSION,
      keyValue,
      data
    );
  }

  async getCache(keyValue) {
    return await this.cacheStorageService.getCaches(
      CACHES_KEY.GETVERSION,
      keyValue
    );
  }

  async setEndpoint() {
    const existCache = await this.getCache(
      `/getversion/?cu_id=${this.code}&session_init=1`
    );
    if (!existCache) {
      this.endpoint = `getversion/?cu_id=${this.code}&session_init=1`;
      return STATUS_CACHES.NO_CACHE;
    }

    this.endpoint = `getversion/?cu_id=${this.code}&session_init=1`;
    this.getversion = existCache;
    return STATUS_CACHES.CACHE;
  }

  urlVersion() {
    const queryParamApi = sessionStorage.getItem('api');
    const customerApi = this.apiUrlService.customerEnvironment();
    return customerApi
      ? `${customerApi}/${this.endpoint}`
      : `${
        API_ENVIRONMENT[`${queryParamApi}Q`] ||
        API_ENVIRONMENT[`${window.hostEnv || 'prod'}Q`]
      }/${this.endpoint}`;
  }

  async httpsVersion() {
    const ts_gv = Date.now();
    debuggerLog('ApiDirectory: getversion call start', Date.now() - window.ts_start);
    let count = 0;
    localStorage.setItem(
      'getVersion',
      `/getversion/?cu_id=${this.code}&session_init=1`
    );
    const result = await this.setEndpoint();
    if (result === STATUS_CACHES.NO_CACHE) {
      window.firstPageView = true;
      debuggerLog('ApiDirectory: getversion firstPageView EVER', window.firstPageView);
    }
    return new Promise((resolve, reject) => {
      const timer = setInterval(async () => {
        let gvFlag = false;
        if (window.finalytics) {
          gvFlag = true;
          if (window.getVersionInSettings === true) {
            gvFlag = (window.getVersionInSettings === true && window.getversion2);
          } else if (window.getVersionInSettings === undefined) {
            gvFlag = false;
          }
        }
        debuggerLog('ApiDirectory: gvFlag', gvFlag);
        if (gvFlag) {
          clearInterval(timer);
          debuggerLog('ApiDirectory: getversion injectsettings getversioninsettings', window.injectSettings, window.getVersionInSettings);
          try {
            debuggerLog('ApiDirectory: getversion api or settings start ms elapsed', Date.now() - ts_gv);
            let data = {};
  
            if (window.getVersionInSettings === true) {
             
              if (!window.getversion2) {
                debuggerLogError('window.getversion2 is not set yet.');
              }
  
              try {
                data = JSON.parse(window.getversion2);
              } catch (e) {
                debuggerLogError('Invalid JSON in window.getversion2: ' + e.message);
              }
            } else {
              const api = new HttpsService();
              debuggerLog('ApiDirectory: getversion api only start ms elapsed', Date.now() - ts_gv);
              data = await api.https({
                type: TYPE_HTTPS.GET,
                fullUrl: this.urlVersion(),
              });
              debuggerLog('ApiDirectory: getversion api only end ms elapsed', Date.now() - ts_gv);
            }
  
            debuggerLog('ApiDirectory: getversion data obtained', Date.now() - ts_gv);
            debuggerLog('ApiDirectory: getversion data obtained', data);
  
            
            if ('data' in data) {
              data = data.data;
            }
  
  
            if (!data || !data.payload) {
              debuggerLogError('Invalid data structure: data or data.payload is missing.');
            }
  
            window.suppress_body = data.payload.suppress_body;
            window.injectSettings = data.payload.inject_settings;
            window.getVersionInSettings = data.payload.getversion_in_settings;
            window.firstVisitEnabled = data.payload.first_visit_enabled;
            debuggerLog(
              'ApiDirectory: getversion suppressbody injectsettings getversioninsettings firstvisitenabled', 
              {
                suppressbody: window.suppress_body,
                injectSettings: window.injectSettings,
                getVersionInSettings: window.getVersionInSettings,
                firstVisitEnabled: window.firstVisitEnabled
              }
            );            
  
            if (window.firstPageView && window.firstVisitEnabled === false) {
              window.skipAdsReplacement = true;
              window.finishedAdsReplacement = true; 
              debuggerLog('ApiDirectory: getversion firstPageView finishedAds', window.skipAdsReplacement);
            }
            debuggerLog('ApiDirectory: getversion api end ms elapsed', Date.now() - ts_gv);
            debuggerLog('ApiDirectory: getversion data', data);
  
            debuggerLog('ApiDirectory: getversion process data start ms elapsed', Date.now() - ts_gv);
  
            if (!Array.isArray(data.pages)) {
              debuggerLogError('ApiDirectory: data.pages is missing or not an array. data:', data);
            }
  
            if (result === STATUS_CACHES.NO_CACHE) {
              window.getversion = data;
              this.getversion = data;
              await this.setCache(
                `/getversion/?cu_id=${this.code}&session_init=1`,
                data
              );
              if (Array.isArray(data.pages)) {
                personalizeAllPages(data.pages, data.payload.personalize_all_pages);
              }
              this.setSessionStorage();
            } else if (result === STATUS_CACHES.CACHE) {
              await this.updateCache(data);
              window.getversion = this.getversion;
              if (Array.isArray(this.getversion.pages)) {
                personalizeAllPages(
                  this.getversion.pages,
                  this.getversion.payload.personalize_all_pages
                );
              }
              this.setSessionStorage();
            }
  
            debuggerLog('ApiDirectory: getversion isArray data.pages', data.pages);
  
            const urlPath = window.location.pathname || '';
            const allPages = data.payload.personalize_all_pages;
            if (window.suppress_body === true && Array.isArray(data.pages)) {
              if ((!data.pages.includes(urlPath) && allPages === false)) {
                debuggerLog('ApiDirectory: getversion set finshedAdsRep to true', Date.now() - ts_gv);
                window.finishedAdsReplacement = true;
              }
            } 
            debuggerLog('ApiDirectory: getversion process data end ms elapsed', Date.now() - ts_gv);
            debuggerLog('ApiDirectory: getversion suppress body start ms elapsed', Date.now() - ts_gv);
            await this.suppressBody();
  
            debuggerLog('ApiDirectory: getversion suppress body end ms elapsed', Date.now() - ts_gv);
            resolve();
          } catch (error) {
            debuggerLog('ApiDirectory Error: ApiDirectoryService', error);
            this.documentBodyService.bodyVisible();
            window.getversion = {
              version: +localStorage.getItem('timestampDefaultValue') || Date.now(),
              personalizationIsActive: false,
            };
            reject(error);
          }
        } else if (count === 60) {
          clearInterval(timer);
          reject(debuggerLogError(`ApiDirectory: Condition for gvFlag not met within time limit (count=${count}).`));
        }
        ++count;
      }, 50);
    });
    debuggerLog('ts: getversion call end', Date.now() - window.ts_start);
  }
}
