import { debuggerLog } from './debuggerLog';

const getRandom = () => Math.floor(Math.random() * 100);

const filterAdsCampaignTypeAb = (ads) => ads.filter(ad => ad && ad.campaign && ad.campaign.campaign_type === 'ab');

const adsToBeShown = (ads) => {
  const singleAlgorithmsTypeFTV = [];
  const moreThanOneAlgorit = [];
  const adsDidNotEnterValidation = [];

  ads && ads.filter(Boolean).forEach((ad) => {
    const validationCampaign = ad.campaign && ad.campaign.partial_delivery_percent !== null && ad.campaign.partial_delivery_percent !== 100 && ad.campaign.campaign_type !== 'ab';
    const algorithms = ad.algorithms || {};
    const validationAlgorithms = Object.keys(algorithms).length === 1 && (Object.keys(algorithms)[0] === 'ftv' || Object.keys(algorithms)[0] === 'segment');
    const validationAlgorithmsLength = Object.keys(algorithms).length > 1;

    if (validationCampaign) {
      if (validationAlgorithms) {
        singleAlgorithmsTypeFTV.push(ad);
      } else if (validationAlgorithmsLength) {
        moreThanOneAlgorit.push(ad);
      } else {
        moreThanOneAlgorit.push(ad);
      }
    } else {
      adsDidNotEnterValidation.push(ad);
    }
  });

  const adsViewInFront = singleAlgorithmsTypeFTV.length !== 0 ? singleAlgorithmsTypeFTV : adsDidNotEnterValidation;
  const mixResults = [...singleAlgorithmsTypeFTV, ...moreThanOneAlgorit, ...adsDidNotEnterValidation];

  debuggerLog('[adsToBeShown] result', { singleAlgorithmsTypeFTV, moreThanOneAlgorit, adsDidNotEnterValidation, adsViewInFront, mixResults });

  return {
    singleAlgorithmsTypeFTV,
    moreThanOneAlgorit,
    adsDidNotEnterValidation,
    adsViewInFront,
    mixResults,
  };
};

const normalizeAds = ({ ads, campaigns, settings, adsWithPersonalizationToBeRemembered, showCampaignBasedOnPdp, params, queryParamsPdp, prevSessionData }) => {
  debuggerLog('[normalizeAds][normalizeAds] input', { ads, campaigns, settings, adsWithPersonalizationToBeRemembered, showCampaignBasedOnPdp, params, queryParamsPdp });

  let numberOfAbTestsAds = 0;
  let abAds = [];
  let listOfAds = [];
  let adsWithCampaigns = [];

  ads.forEach((ad, index) => {
    debuggerLog('[normalizeAds] iteration', ad);
    ad.originalIndex = index;
    const filteredCampaigns = ad.campaigns ? campaigns.filter((campaign) => ad.campaigns.includes(campaign.id)) : [];
    debuggerLog('[normalizeAds] filteredCampaigns', filteredCampaigns);

    if (filteredCampaigns.length) {
      // an ad is created for each campaign
      const adsWithPdp = filteredCampaigns.map((campaign) => {
        const adAndCampaign = { ...ad, campaign };
        const { description, start_dt: campaignStartDt, end_dt: campaignEndDt, category, subcategory, delivery_type, ids_or_classes, ordinals, pages } = campaign;

        const start_dt = ad.start_dt || campaignStartDt;
        const end_dt = ad.end_dt || campaignEndDt;

        const key = delivery_type && delivery_type === 'class' ? 'class' : delivery_type === 'div' ? 'div' : 'other';
        debuggerLog('[normalizeAds] adsWithPdpmap input', adAndCampaign);
        const adProps = { description, start_dt, end_dt, category, subcategory, ordinals, pages };
        const deliveryType = {
          class: {
            ...adAndCampaign,
            ...adProps,
            div_id: null,
            delivery_type: 'class',
          },
          div: {
            ...adAndCampaign,
            ...adProps,
            delivery_type: 'div_id',
          },
          other: {
            ...adAndCampaign,
            ...adProps,
            delivery_type: adAndCampaign.delivery_type,
          }
        };
        return deliveryType[key];
      });

      adsWithCampaigns.push(...adsWithPdp);
    } else {
      const randomShowAd = getRandom();
      if (randomShowAd <= (params.pdp || settings.partial_delivery_percent)) {
        listOfAds.push({
          ...ad,
          showAd: true,
          campaigns: null,
        });
      }
    }
  });

  debuggerLog('[normalizeAds] adsWithCampaigns', adsWithCampaigns);

  // This portion of code calculates the PDP for every ad in the campaign, instead of
  // evaluating the whole campaign. This idea will be saved for future consideration
  // Try to get the partial delivery percent directly from the campaign
  // If it doesn't exist, use the company-level partial delivery percent
  // const currentPdp = typeof addWithPdp.campaign.partial_delivery_percent === 'number'
  //   ? addWithPdp.campaign.partial_delivery_percent
  //   : settings.partial_delivery_percent;

  // To retrieve the boolean that indicates if personalization should be remembered, first,
  // look for the value in the campaign, and in case it doesn't exist, look for the company-level value
  adsWithCampaigns.forEach((adWithPdp) => {
    const alwaysRememberPdp = typeof adWithPdp.campaign.always_remember_users_for_partial_delivery === 'boolean'
      ? adWithPdp.campaign.always_remember_users_for_partial_delivery
      : settings.always_remember_users_for_partial_delivery;

    let showAd = false;

    // Check if the ad is already viewed and inactive in prevSessionData
    const adKey = `idAd_${adWithPdp.id}idCamp_${adWithPdp.campaign.id}`;
    debuggerLog('[normalizeAds] adKey', adKey);
    debuggerLog('[normalizeAds] adKey ads_viewed prevSessionData', prevSessionData);
    if (prevSessionData && prevSessionData.ads_viewed && prevSessionData.ads_viewed[adKey] && !prevSessionData.ads_viewed[adKey].activeAd) {
      showAd = false;
      debuggerLog(`[normalizeAds] ad ${adWithPdp.id} is not active in prevSessionData`, adWithPdp);
    } else {
      if (queryParamsPdp && !isNaN(+queryParamsPdp)) {
        debuggerLog('[queryParamsPdp] queryParamsPdp if input', queryParamsPdp);
        const objValidation = {
          viewAd: {
            ...adWithPdp,
            showAd: true,
            rememberPersonalization: false,
          },
          noViewAd: {
            ...adWithPdp,
            showAd: false,
            rememberPersonalization: false,
          },
          luckAd: {
            ...adWithPdp,
            showAd: getRandom() <= +queryParamsPdp,
            rememberPersonalization: false,
          }
        };
        const key = +queryParamsPdp === 100 ? 'viewAd' : +queryParamsPdp === 0 ? 'noViewAd' : 'luckAd';
        listOfAds = [...listOfAds, objValidation[key]];
        return;
      }

      if (adWithPdp.campaign.campaign_type === 'ab') {
        numberOfAbTestsAds += 1;

        if (adsWithPersonalizationToBeRemembered[adWithPdp.id] !== undefined) {
          showAd = adsWithPersonalizationToBeRemembered[adWithPdp.id];
        } else {
          showAd = (showCampaignBasedOnPdp[adWithPdp.campaign.id] && numberOfAbTestsAds === 1) ||
            (!showCampaignBasedOnPdp[adWithPdp.campaign.id] && numberOfAbTestsAds === 2);

          if (alwaysRememberPdp) {
            adsWithPersonalizationToBeRemembered[adWithPdp.id] = showAd;
          }
        }
      } else if (showCampaignBasedOnPdp[adWithPdp.campaign.id]) {
        showAd = true;
        adsWithPersonalizationToBeRemembered[adWithPdp.id] = true;
      } else if (adsWithPersonalizationToBeRemembered[adWithPdp.id] !== undefined) {
        showAd = adsWithPersonalizationToBeRemembered[adWithPdp.id];
      } else {
        showAd = getRandom() <= (params.pdp || settings.partial_delivery_percent);
      }
    }

    adWithPdp.showAd = showAd;
    adWithPdp.rememberPersonalization = alwaysRememberPdp;
    listOfAds.push(adWithPdp);

    if (adWithPdp.campaign.campaign_type === 'ab') {
      abAds.push(adWithPdp);
    }

    debuggerLog(`[normalizeAds] adWithPdp ${adWithPdp.id} showAd: ${showAd}`, adWithPdp);
  });

  if (abAds.length > 0) {
    const abAdsToShow = abAds.filter(ad => ad.showAd);
    debuggerLog('[normalizeAds] abAdsToShow', abAdsToShow);
    if (abAdsToShow.length === 0) {
      const randomAbAd = Math.floor(Math.random() * abAds.length);
      const abAd = abAds[randomAbAd];

      if (adsWithPersonalizationToBeRemembered[abAd.id] === undefined) {
        abAd.showAd = true;
        adsWithPersonalizationToBeRemembered[abAd.id] = true;
        listOfAds = listOfAds.map(ad => ad.id === abAd.id ? { ...ad, showAd: true } : ad);
      }
    }
  }

  listOfAds.sort((a, b) => a.originalIndex - b.originalIndex);
  const adsShown = adsToBeShown(listOfAds);
  const AdsCampaignTypeAb = filterAdsCampaignTypeAb(listOfAds);
  debuggerLog('[normalizeAds] adsToBeShown mappedAds', { adsShown, AdsCampaignTypeAb });

  return {
    adsShown,
    AdsCampaignTypeAb
  };
};

export {
  normalizeAds,
  getRandom,
};
