/* eslint-disable no-console */
export const CACHES_KEY = Object.freeze({
  GETVERSION: 'getVersion',
  GETADS: 'getAds'
});

export class CacheStorageService {
  static instance;
  constructor() {
    return CacheStorageService.instance || (CacheStorageService.instance = this);
  }

  async setCaches(keyCache, data) {
    const cache = await caches.open(keyCache);
    cache.addAll(data);
  }

  async setOneCaches(keyCache, keyValue, data) {
    const cache = await caches.open(keyCache);
    await cache.put(keyValue, new Response(JSON.stringify(data)));
  }

  async getCaches(keyCache, keyValue) {
    if (!(await caches.has(keyCache))) return;

    const cache = await caches.open(keyCache);

    const result = await cache.match(keyValue);
    if (!result) return;

    const cacheValue = await result.text();
    if (!cacheValue) return;

    return JSON.parse(cacheValue);
  }

  async clearCaches(value) {
    const keys = await caches.keys();
    if(value) return await caches.delete(value);
    if(keys.length) await Promise.all(keys.map(key => caches.delete(key)));
  }
}
