import {
  FORAGE_KEY,
  LocalForageService,
  SUPRESS_CONTROL,
  SuppressBodyService,
} from '../service';
import { debuggerLog } from './debuggerLog';
import { alertHtml } from './htmlHelp';

const localForageService = new LocalForageService();

let globalAdViews = [];

const AssigningTime = (units, timeframe) => {
  const time = {
    seconds: 1000,
    minutes: 60000,
    hours: 60000 * 60,
    days: 60000 * 60 * 24,
  };
  const result = time[units] * timeframe;
  return result;
};

const isObject = (item) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
};

const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
};

const normalizeAds = (adsArray, prevSessionData) => {
  debuggerLog('[expiration ads][normalizeAds] input 1', { adsArray, prevSessionData });
  const adsViewed = prevSessionData?.ads_viewed || {};
  if (JSON.stringify(adsArray) !== '{}') {
    adsArray.forEach(ad => {
      debuggerLog('[expiration ads][normalizeAds]  input', ad);
      const {
        id: idAd,
        campaigns: idCampaign,
        div_id,
        div_class,
        name,
        aggressive_expire,
        alert,
        expiration_timeframe,
        expiration_timeframe_units,
        expiration_views,
        end_dt,
        start_dt
      } = ad;
      let alertNames = ['skip-to-content', 'alert__container', 'alerts', 'alert'];
      const firstViewTimestamp = ad.firstViewTimestamp || null;
      const normalizeAd = {
        activeAd: true,
        aggresiveExpire: aggressive_expire,
        alert: alert ||
          (typeof ad?.ad_html === 'string' && ad.ad_html.toLowerCase().includes('data-finalytics-alert="true"')) ||
          alertNames.includes(ad.div_id) ||
          alertNames.includes(ad.div_class) ||
          alertNames.includes(ad.div_tracking_label),
        expirationTimeframe: expiration_timeframe || null,
        expirationTimeframeUnits: expiration_timeframe_units || null,
        expirationViews: expiration_views || null,
        name,
        expireAt: (expiration_timeframe && AssigningTime(expiration_timeframe_units, expiration_timeframe)) || null,
        totalViews: 0,
        idAd: ad.id,
        div_id,
        div_class,
        end_dt,
        start_dt,
        firstViewTimestamp,
      };

      if (idCampaign && idCampaign.length) {
        debuggerLog('[expiration ads][normalizeAds]  if', ad);
        idCampaign.forEach(
          (campaign) =>
            !adsViewed.hasOwnProperty(`idAd_${idAd}idCamp_${campaign}`) &&
            (adsViewed[`idAd_${ad.id}idCamp_${campaign}`] = {
              ...normalizeAd,
              idCampaign: campaign,
            })
        );
      } else {
        adsViewed[`idAd_${idAd}idCamp_${ad.campaigns}`] = {
          ...normalizeAd,
          idCampaign,
        };
      }
    });
    debuggerLog('[expiration ads][normalizeAds]  result', adsViewed);
    return adsViewed;
  }
  debuggerLog('[expiration ads][normalizeAds]  else', adsViewed);
  return adsViewed;
};

const existeAdLocalForage = (adsStored, inputAds) => {
  // TODO: REFACTOR
  debuggerLog('[expiration ads][existeAdLocalForage] input', {
    adsStored,
    inputAds,
  });
  if (JSON.stringify(adsStored) !== '{}' && JSON.stringify(inputAds) !== '{}') {
    debuggerLog('[expiration ads][existeAdLocalForage] validation if');
    const newAds = Object.values(inputAds).map(
      ({
        idAd,
        idCampaign,
        expirationTimeframeUnits,
        expirationTimeframe,
        alert,
        name,
      }) => ({
        idAd,
        idCampaign,
        expirationTimeframeUnits,
        expirationTimeframe,
        alert,
        name,
      })
    );
    const oldAds = Object.values(adsStored).map(
      ({
        idAd,
        idCampaign,
        expirationTimeframeUnits,
        expirationTimeframe,
        alert,
        name,
      }) => ({
        idAd,
        idCampaign,
        expirationTimeframeUnits,
        expirationTimeframe,
        alert,
        name,
      })
    );
    const newAdSaveLocalForage = [];
    let storeAds = {};
    newAds.forEach(
      (ad) =>
        !JSON.stringify(oldAds).includes(JSON.stringify(ad)) &&
        newAdSaveLocalForage.push({ idAd: ad.idAd, idCampaign: ad.idCampaign })
    );
    new Map(Object.entries(inputAds)).forEach((value, key) =>
      newAdSaveLocalForage.forEach(
        ({ idAd, idCampaign }) =>
          value.idAd === idAd &&
          value.idCampaign === idCampaign &&
          (storeAds = { ...storeAds, [key]: value })
      )
    );
    new Map(Object.entries(adsStored)).forEach((value, key) =>
      Object.keys(storeAds).forEach(
        (ad) => ad === key && (storeAds[key].totalViews = value.totalViews)
      )
    );
    debuggerLog('[expiration ads][existeAdLocalForage] result', storeAds);
    return storeAds;
  }
  debuggerLog('[expiration ads][existeAdLocalForage] else');
  return {};
};

const normalizeAdsExpiration = async (expirations) => {
  debuggerLog('[expiration ads][normalizeAdsExpiration] input', {
    expirations,
  });
  const prevSessionDataExpirationHandlere =
    (await localForageService.getForage(FORAGE_KEY.SESSION_DATA)) || {};
  const validationNormalizeAdsExpiration =
    JSON.stringify(prevSessionDataExpirationHandlere) !== '{}' &&
    JSON.stringify(prevSessionDataExpirationHandlere.ads_viewed) !== '{}';
  let adsMap = {};
  if (validationNormalizeAdsExpiration) {
    debuggerLog('[expiration ads][normalizeAdsExpiration] if');
    const adsStored =
      prevSessionDataExpirationHandlere &&
      prevSessionDataExpirationHandlere.ads_viewed;

    expirations.forEach((ad) => {
      if (ad.campaigns && ad.campaigns.length) {
        ad.campaigns.forEach((campaignId) => {
          const adKey = `idAd_${ad.id}idCamp_${campaignId}`;
          if (adsStored[adKey]) {
            adsMap[adKey] = adsStored[adKey];
          }
        });
      } else {
        const adKey = `idAd_${ad.id}idCamp_isNull`;
        if (adsStored[adKey]) {
          adsMap[adKey] = adsStored[adKey];
        }
      }
    });

    debuggerLog('[expiration ads][normalizeAdsExpiration] result ', { adsMap });
    return adsMap;
  }
  debuggerLog('[expiration ads][normalizeAdsExpiration] result', { adsMap });
  return adsMap;
};

const expirationHandler = async (expirationsAds, poolGroup, splitOrdinals) => {
  debuggerLog('[expiration ads][expirationHandler] expirationsAds [EH]', { expirationsAds: expirationsAds || [] });
  let expirationObj = {};
  let adsViewed = {};
  const urlParams = new URLSearchParams(window.location.search);

  const ageViews = +urlParams.get('age__views') || 0;
  const ageDays = +urlParams.get('age__days') || 0;
  const ageHours = +urlParams.get('age__hours') || 0;
  const ageMinutes = +urlParams.get('age__minutes') || 0;
  const startShiftDays = +urlParams.get('start__shift_days') || 0;
  const aggressiveExpire = urlParams.get('expire__aggressive') === '1';
  const emulationDate = urlParams.get('emulation_date') || localStorage.getItem('emulation_date');
  const adIdsParam = urlParams.get('ad_ids');
  const adIds = adIdsParam ? adIdsParam.split(',').map(id => id.trim()) : null;
  if (emulationDate) {
    localStorage.setItem('emulation_date', emulationDate);
  }

  let allowedCounts = {};

  if (poolGroup && Array.isArray(poolGroup)) {
    let ids = [];
    let classes = [];
    let ordinals = null;

    poolGroup.forEach(item => {
      if (item.id) ids.push(item.id);
      if (item.class) classes.push(item.class);
      if (item.ordinal) ordinals = item.ordinal;
    });

    let identifiers = [...ids, ...classes];
    const num_ords = splitOrdinals(poolGroup, identifiers);

    identifiers.forEach((identifier, index) => {
      allowedCounts[identifier] = num_ords[index] || 1;
    });
  }
   
  debuggerLog('[force age-ad params]', { ageViews, ageDays, ageHours, ageMinutes, startShiftDays, aggressiveExpire, emulationDate, adIdsParam });
  const minutes = +localStorage.getItem('controlTimeSession') || 0;
  const minutesElapsed = minutes * 60000;
  const currentTime = emulationDate ? new Date(emulationDate) : new Date();
  let alertValue = false;

  const processedIds = new Set();
  const processedClasses = new Set();

  const processAd = async (ad) => {
    debuggerLog('[expiration ads][expirationHandler] adIdsadIds ', { adIdsindi: ad.idAd ? ad.idAd.toString() : 'undefined', adIds , ad});
    if (adIds && ad.id && !adIds.includes(ad.id.toString())) {
      debuggerLog('[expiration ads][expirationHandler] Skipping ad', { adID: ad.id ? ad.id.toString() : 'undefined' });
      return false;
    }    
    const expirations = await normalizeAdsExpiration([ad]);
    debuggerLog('[expiration ads][expirationHandler] input', { expirations });
    const prevSessionData = await localForageService.getForage(FORAGE_KEY.SESSION_DATA) || {};
    const adsDismissedSet = new Set(prevSessionData.ads_dismissed || []);

    let adUpdated = false;
    for (let [key, ad] of Object.entries(expirations)) {
      if (ad.alert && ad.activeAd === false) alertValue = true;
      debuggerLog('[expiration ads][expirationHandler] input 3', { wts: window.timestamp, adExAt: ad.expireAt, firstViewTimestamp: ad.firstViewTimestamp });
      if (emulationDate) {
        ad.firstViewTimestamp = currentTime.getTime();
      }
      if (!ad.firstViewTimestamp) {
        debuggerLog('[expiration ads][expirationHandler] !firstViewTimestamp', { firstViewTimestamp: ad.firstViewTimestamp });
        ad.firstViewTimestamp = currentTime.getTime();
      }

      
      /***** Simulate Aging START *****/
      if (ageViews && ad.expirationViews <= ageViews) {
        ad.totalViews = ageViews + 1;
      }

      
      if (ageDays) {
        const agedTimestamp = new Date(currentTime.getTime() - AssigningTime('days', ageDays)); // Age by days
        ad.firstViewTimestamp = agedTimestamp.getTime();
      }

      if (ageHours) {
        const agedTimestamp = new Date(currentTime.getTime() - AssigningTime('hours', ageHours)); // Age by hours
        ad.firstViewTimestamp = agedTimestamp.getTime();
      }

      if (ageMinutes) {
        const agedTimestamp = new Date(currentTime.getTime() - AssigningTime('minutes', ageMinutes)); // Age by minutes
        ad.firstViewTimestamp = agedTimestamp.getTime();
      }

      if (startShiftDays) {
        const shiftedStartDate = new Date(currentTime.getTime() - AssigningTime('days', startShiftDays));
        ad.start_dt = shiftedStartDate.toISOString(); 
        debuggerLog('[expiration ads][expirationHandler] start date shifted', { start_dt: ad.start_dt });
      }

      if (aggressiveExpire) {
        ad.aggresiveExpire = true;
      }

      // CHECKING FOR AGGRESSIVE EXPIRE
      let expiredByAggressiveExpire = false;

      // Check expiration by views first if aggressiveExpire is true
      if (aggressiveExpire && ad.expirationViews !== null) {
        if (ad.totalViews >= ad.expirationViews) {
          expiredByAggressiveExpire = true;
        }
      }

      // If the ad has not expired by views, check by time
      if (aggressiveExpire && !expiredByAggressiveExpire && ad.expireAt !== null) {
        if (Date.now() >= ad.firstViewTimestamp + ad.expireAt) {
          expiredByAggressiveExpire = true;
        }
      }

      /***** Simulate Aging END *****/

      const isExpiredByTime = ad.expireAt !== null && Date.now() >= ad.firstViewTimestamp + ad.expireAt;
      const isDismissed = adsDismissedSet.has(ad.idAd);

      const isWithinStartEndDate = (!ad.start_dt || new Date(ad.start_dt) <= currentTime) &&
        (!ad.end_dt || new Date(ad.end_dt) >= currentTime);

      const isExpiredByViews = ad.expirationViews !== null && (ad.expirationViews === 'noViews' || ad.totalViews >= ad.expirationViews);

      debuggerLog('[expiration ads][expirationHandler] isWithinStartEndDate', { isExpiredByViews, isExpiredByTime, isDismissed, isWithinStartEndDate });

      const activeAd = !(isDismissed || (expiredByAggressiveExpire) || (ad.aggresiveExpire ? (isExpiredByViews && !ad.alert) || isExpiredByTime
        : ((isExpiredByViews && !ad.alert) && isExpiredByTime) || (ad.expirationViews === null && isExpiredByTime) || (ad.expireAt === null && isExpiredByViews && !ad.alert)) ||
        !isWithinStartEndDate);
      debuggerLog('[expiration ads][expirationHandler] activeAd', { activeAd });

      ad.showAd = activeAd;
      expirationObj[key] = {
        ...ad,
        activeAd,
        totalViews: activeAd ? (ad.totalViews ?? 0) + 1 : ad.totalViews,
        firstViewTimestamp: ad.firstViewTimestamp,
      };
      if (activeAd) {
        adUpdated = true;
        adsViewed[key] = expirationObj[key];
      }
    }

    return adUpdated;
  };

  debuggerLog('[expiration ads][expirationHandler] allowedCounts', {allowedCounts});
  for (const ad of expirationsAds) {
    if (adIds && processedIds.size > 0) {
      debuggerLog('[expiration ads][expirationHandler] Already processed an ad, skipping remaining ads');
      break;
    }
  
    if (ad.showAd) {
      let identifier = ad.div_class || ad.div_id;
      if (!identifier) continue; // Skip if no valid identifier
  
      if (ad.is_multiprod) {
        let remaining = allowedCounts[identifier] || 0;
  
        if (remaining > 0) {
          const adUpdated = await processAd(ad);
          if (adUpdated) {
            allowedCounts[identifier] -= 1;
          }
        } else {
          debuggerLog('[expiration ads][expirationHandler] Skipping pooled ad due to poolGroup limit', { identifier, remaining });
        }
      } else {
        if (identifier in allowedCounts && allowedCounts[identifier] <= 0) {
          debuggerLog('[expiration ads][expirationHandler] Skipping non-poolGroup ad as its identifier was used up', { identifier });
          continue;
        }
  
        if (ad.div_id && !processedIds.has(ad.div_id)) {
          processedIds.add(ad.div_id);
          const adUpdated = await processAd(ad);
          if (!adUpdated) {
            processedIds.delete(ad.div_id); 
          }
        } else if (ad.div_class && !processedClasses.has(ad.div_class)) {
          processedClasses.add(ad.div_class);
          const adUpdated = await processAd(ad);
          if (!adUpdated) {
            processedClasses.delete(ad.div_class);
          }
        }
      }
    }
  }

  debuggerLog('[expiration ads][expirationHandler] result', expirationObj);

  const prevSessionDataExpirationHandler =
    (await localForageService.getForage(FORAGE_KEY.SESSION_DATA)) || {};
  prevSessionDataExpirationHandler.ads_viewed = {
    ...prevSessionDataExpirationHandler.ads_viewed,
    ...expirationObj,
  };

  debuggerLog(
    '[expiration ads][expirationHandler] expirationHandler ads_viewed',
    prevSessionDataExpirationHandler.ads_viewed
  );
  debuggerLog('[FINA-295]', prevSessionDataExpirationHandler.ads_viewed);

  await localForageService.setForage(
    FORAGE_KEY.SESSION_DATA,
    prevSessionDataExpirationHandler
  );

  return prevSessionDataExpirationHandler.ads_viewed;
};

/**
 * This function is used to determine if an ad has been viewed and if it has, it will determine if the
 * ad has expired
 * @param prevSessionData - The previous session data.
 * @returns The adsViewed object is being returned.
 * In this part we create everything that is going to be stored in the localforage ad.expiration_views and
 * /
 * ad.expiration_timeframe and ad.expiration_timeframe_units ad.aggressive_expire
 * In this part the ad.expiration views decrement is also done and in the future I will now know about ad.aggressive_expire once we have the prop
 * The ads are saved idAd_${Id example}IDCampaign_${Id example}  campaign can be null, in case the campaign does not come it will be saved as isNull idAd_32IDCampaign_isNull
 * idAd_32IDCampaign_1 {
 * activeAd:true,                      We control the ad with active Ads if it is true the ad is shown if it is false it is not shown
 * aggresiveExpire:false                if it is false it is shown if it is true, activated it would be false
 * expirationTimeframe: 1668023610146   These numbers are a date, if the date is < Date.now() the  becomes false
 * expirationTimeframeUnits             It is not yet useful, but it came in the issue
 * expirationViews: 1                   expirationViews controls the number of times an ad can be viewed if <= 0 activeAd = false
 * idAd:123                             is the id of the ad not null
 * idCampaign 123 || null               the ide of the campaign, this can be null, there may be ads that your campaign is null, in this case we identify them only with the adID
 * }
 *
 *
 * Where you validate the activeAd this happens in getAdsWithPDP line 3040
 */
const adsViewedAndExpirationOfAds = async (
  prevSessionData,
  viewAdsAndCampaigns,
  fnv
) => {
  debuggerLog('[expiration ads][adsViewedAndExpirationOfAds] input', {
    prevSessionData,
    viewAdsAndCampaigns,
    fnv,
  });

  const validationPrevSessionData =
    JSON.stringify(prevSessionData) !== '{}' &&
    JSON.stringify(prevSessionData.ads_viewed) !== '{}';
  const validatioAdViewed = [undefined, null].includes(prevSessionData.ads_viewed);

  let newAdsNormalized = {};

  if (validatioAdViewed) {
    debuggerLog(
      '[expiration ads][adsViewedAndExpirationOfAds] validatioAdViewed if'
    );
    prevSessionData.ads_viewed = {};
  } else if (validationPrevSessionData) {
    debuggerLog('[expiration ads][adsViewedAndExpirationOfAds] if else ');
    const newAds = normalizeAds(viewAdsAndCampaigns, prevSessionData);

    debuggerLog(
      '[expiration ads][adsViewedAndExpirationOfAds] newAds',
      newAds
    );
    if (JSON.stringify(prevSessionData.ads_viewed) !== '{}') {
      debuggerLog('[expiration ads][adsViewedAndExpirationOfAds] if');
      newAdsNormalized = existeAdLocalForage(
        prevSessionData.ads_viewed,
        newAds
      );
    } else {
      debuggerLog('[expiration ads][adsViewedAndExpirationOfAds] else');
      newAdsNormalized = newAds;
    }
    debuggerLog(
      '[expiration ads][adsViewedAndExpirationOfAds] result normalizeAds',
      newAdsNormalized
    );
  }

  let item = JSON.parse(localStorage.getItem('ads_viewed') || 'false'),
    adsViewed;
  debuggerLog(
    '[expiration ads][adsViewedAndExpirationOfAds] item',
    typeof item
  );

  if (validationPrevSessionData) {
    adsViewed = { ...prevSessionData.ads_viewed, ...newAdsNormalized };
    debuggerLog(
      '[expiration ads][adsViewedAndExpirationOfAds] validationPrevSessionData if result',
      { adsViewed, fnv }
    );
  } else {
    adsViewed = { ...normalizeAds(viewAdsAndCampaigns, prevSessionData) };
    debuggerLog(
      '[expiration ads][adsViewedAndExpirationOfAds] validationPrevSessionData else result',
      { adsViewed, fnv }
    );
  }

  if (adsViewed) {
    for (const key in adsViewed) {
      if (adsViewed[key].alert && adsViewed[key].activeAd) {
        globalAdViews.push(adsViewed[key]);
      }
    }
  }

  if (Object.keys(item).length) {
    adsViewed = item;
    debuggerLog(
      '[expiration ads][adsViewedAndExpirationOfAds] item if ',
      adsViewed
    );
  }

  const prevSessionDataUpdate =
    (await localForageService.getForage(FORAGE_KEY.SESSION_DATA)) || {};

  if (JSON.stringify(prevSessionData) !== '{}') {
    debuggerLog('[expiration ads][adsViewedAndExpirationOfAds] if', adsViewed);
    prevSessionDataUpdate.ads_viewed = adsViewed;
    await localForageService.setForage(
      FORAGE_KEY.SESSION_DATA,
      prevSessionDataUpdate
    );
  }

  debuggerLog(
    '[expiration ads][adsViewedAndExpirationOfAds] final result',
    { prevSessionDataUpdate, adsViewed, fnv }
  );
  
  return adsViewed;
};

// ,
//
/**
 * It takes an array of objects, and returns an array of unique values from the objects' properties
 * @param elemete - is an array of objects that contains the id of the ad and the id of the campaign
 * This helps us to accurately identify the ad and make the respective decrement of expiration_views and the handling of the other props
 */

const hydrateViweAd = (adsViewInFront) => {
  debuggerLog('[expiration ads][hydrateViweAd] input', adsViewInFront);
  let campsID = [];

  if (Array.isArray(adsViewInFront) && adsViewInFront.length > 0) {
    const newArrayCampainID = adsViewInFront
      .map((ad) => Array.isArray(ad.campaigns) ? ad.campaigns : [])
      .flat(Infinity)
      .filter(Boolean);
    const arrayCampsID = new Set(newArrayCampainID);
    campsID = [...arrayCampsID];
    debuggerLog('[expiration ads][hydrateViweAd] campsID if', campsID);
  } else {
    debuggerLog('[expiration ads][hydrateViweAd] newArryAdsID else');
    campsID = [];
  }
  return campsID;
};

const getCampsIDView = async (adsViewInOrder, poolGroup, splitOrdinals) => {
  const ads = await expirationHandler(adsViewInOrder, poolGroup, splitOrdinals);
  let getAdsViewLocalForage = {};
  new Map(Object.entries(ads)).forEach(
    (value) => {
      getAdsViewLocalForage[value.idAd] = value;
    }
  );
  debuggerLog('[expiration ads][removeExpiredAds] getCampsIDView', getAdsViewLocalForage);
  return getAdsViewLocalForage;
};

const getAdsByCampaigns = (adsWithPdp) =>
  adsWithPdp.reduce((acc, currentAd) => {
    debuggerLog('[FINA-168] currentAd', currentAd);
    if (currentAd.showAd !== undefined && currentAd.rememberPersonalization) {
      debuggerLog('[FINA-201] test', {
        ...acc,
        [currentAd.id]: currentAd.showAd,
      });
      return {
        ...acc,
        [currentAd.id]: currentAd.showAd,
      };
    }
    return acc;
  }, {});

/**
   * Receives an array of ads and if two or more of them have the
   * same div_id or div_class, it only keeps the first one
  //  * @param {Array} ads
  //  * @returns {Array}
   */

const removeIdsAndClassesDuplicates = async (ads, fn) => {
  debuggerLog('[Finalytics Pixel][removeIdsAndClassesDuplicates] input', {
    ads,
    fn,
  });
  const repeatedIds = {};
  const repeatedClasses = {};
  const adsWithUniqueIdsAndClasses = [];
  ads.forEach((currentAd) => {
    if (currentAd.showAd) {
      if (currentAd.div_id) {
        const currentId = currentAd.div_id;

        // Only add current ad if its ID does not already exist
        if (!repeatedIds[currentId]) {
          adsWithUniqueIdsAndClasses.push(currentAd);
          repeatedIds[currentId] = true;
        }
      } else if (currentAd.div_class) {
        const currentClass = currentAd.div_class;

        // Only add current ad if its class does not already exist
        if (!repeatedClasses[currentClass]) {
          adsWithUniqueIdsAndClasses.push(currentAd);
          //repeatedClasses[currentClass] = true;
        }
      }
    }
  });

  debuggerLog('[Finalytics Pixel][removeIdsAndClassesDuplicates] result', {
    repeatedIds,
    repeatedClasses,
    adsWithUniqueIdsAndClasses,
  });
  return adsWithUniqueIdsAndClasses;
};


const removeExpiredAds = async (adsViewInFront, poolGroup, splitOrdinals) => {
  debuggerLog('[expiration ads][removeExpiredAds] input', { adsViewInFront });

  const prevSessionData1 = (await localForageService.getForage(FORAGE_KEY.SESSION_DATA)) || {};
  const adsViewed = await adsViewedAndExpirationOfAds(prevSessionData1, adsViewInFront, 'removeExpiredAds');

  debuggerLog('[expiration ads][removeExpiredAds] result adsViewedAndExpirationOfAds', adsViewed);

  adsViewInFront.forEach(ad => {
    debuggerLog('[expiration ads][removeExpiredAds] inside adsViewInFront ', ad);
    debuggerLog('[expiration ads][removeExpiredAds] inside adsViewed ', adsViewed);
    if (ad.campaigns && ad.campaigns.length > 0) {
      debuggerLog('[expiration ads][removeExpiredAds] inside  ad.campaigns ',  ad.campaigns);
      ad.campaigns.forEach(campaignId => {
        const adKey = `idAd_${ad.id}idCamp_${campaignId}`;
        debuggerLog('[expiration ads][removeExpiredAds] inside adKey ', adKey);
        debuggerLog('[expiration ads][removeExpiredAds] inside adsViewInFront ', ad);
        debuggerLog('[expiration ads][removeExpiredAds] inside adsViewed[adKey ', adsViewed[adKey]);
        if (adsViewed[adKey] && !adsViewed[adKey].activeAd) {
          debuggerLog('[expiration ads][removeExpiredAds] inside adsViewed[adKey].activeAd) ', {activeAd: adsViewed[adKey].activeAd});
          ad.showAd = false;
        }
      });
    } else {
      const adKey = `idAd_${ad.id}idCamp_isNull`;
      debuggerLog('[expiration ads][removeExpiredAds] elseeeee ', adKey);
      if (adsViewed[adKey] && !adsViewed[adKey].activeAd) {
        ad.showAd = false;
      }
    }
  });
  
  const campsIDView = hydrateViweAd(adsViewInFront);
  
  const getAdsViewLocalForage = await getCampsIDView(adsViewInFront, poolGroup, splitOrdinals);
  const newAds = [];
  debuggerLog('[expiration ads][removeExpiredAds] getAdsViewLocalForage ', getAdsViewLocalForage);
  const adsViewInFrontFilter = adsViewInFront.filter(mappedAd => {
    debuggerLog('[expiration ads][removeExpiredAds] mappedAd ', mappedAd);
    if (mappedAd.id in getAdsViewLocalForage) {
      debuggerLog('[expiration ads][removeExpiredAds] mappedAd.id in getAdsViewLocalForage ', mappedAd);
      const adInLocalForage = getAdsViewLocalForage[mappedAd.id];
      debuggerLog('[expiration ads][removeExpiredAds]adInLocalForage ', adInLocalForage);
      const isCampaignsArray = mappedAd.campaigns !== null &&
        mappedAd.campaigns.some(campaignId => campsIDView.includes(campaignId)) &&
        adInLocalForage.activeAd;
      debuggerLog('[expiration ads][removeExpiredAds]isCampaignsArray ', {isCampaignsArray});
      const isCampaignsNull = mappedAd.campaigns === null && adInLocalForage.activeAd;
      debuggerLog('[expiration ads][removeExpiredAds]isCampaignsNull ', {isCampaignsNull});
      return isCampaignsArray || isCampaignsNull;
    } else {
      debuggerLog('[expiration ads][removeExpiredAds] newAds ad ', {newAds: mappedAd});
      newAds.push(mappedAd);
    }
    return false;
  });


  debuggerLog('[expiration ads][removeExpiredAds] newAds fin', {newAds, adsViewInFrontFilter});
  // Remove duplicate IDs and classes
  const adsViewInOrder = await removeIdsAndClassesDuplicates(adsViewInFrontFilter);

  let adsWithPdp = [];
  if (adsViewInOrder.length !== 0) {
    adsWithPdp = Array.from(new Set(adsViewInOrder));
  } else {
    adsWithPdp = newAds;
  }

  let adsWithPersonalizationToBeRemembered = getAdsByCampaigns(adsWithPdp);
  debuggerLog('[expiration ads][removeExpiredAds] final result', {
    adsWithPdp,
    adsWithPersonalizationToBeRemembered,
    validation: adsWithPdp.length && localStorage.getItem('suppress_body') === SUPRESS_CONTROL.SUPPRESS,
  });

  const suppressBodyService = new SuppressBodyService();
  if (adsWithPdp.length && localStorage.getItem('suppress_body') === SUPRESS_CONTROL.SUPPRESS) {
    suppressBodyService.showElements(adsWithPdp.map((ad) => (ad.div_id && `#${ad.div_id}`) || (ad.div_class && `.${ad.div_class}`)));
  } else {
    suppressBodyService.showElementsAll(null, 'removeExpiredAds');
  }

  return { adsWithPdp, adsWithPersonalizationToBeRemembered };
};








const disguiseAlert = async () => {
  const removeAlert =
      (await localForageService.getForage(FORAGE_KEY.SESSION_DATA)) || {},
    foraje = { ...removeAlert };
  debuggerLog('[disguiseAlert ads][Alert] start', foraje.ads_viewed);
  const elemet = foraje.ads_viewed;
  for (const key in elemet) {
    debuggerLog('[disguiseAlert ads][Alert] for', foraje);
    if (elemet[key].alert) {
      debuggerLog('[disguiseAlert ads][Alert] if');
      elemet[key].activeAd = false;
    }
  }
  debuggerLog('[disguiseAlert ads][Alert] removeAlert finaly', foraje);
  localForageService.setForage(FORAGE_KEY.SESSION_DATA, foraje);
  window.onbeforeunload = () => {
    localForageService.setForage(FORAGE_KEY.SESSION_DATA, foraje);
  };
};

const getUniqueAdIds = (ads) => {
  const uniqueAds = new Map();

  ads.forEach((ad) => {
    if (!uniqueAds.has(ad.idAd)) {
      uniqueAds.set(ad.idAd, ad);
    }
  });

  return Array.from(uniqueAds.values());
};

const handleDismissedAds = () => {
  setTimeout(async () => {
    debuggerLog('[handleDismissedAds] Checking for ads to add close handlers', globalAdViews);
    const prevSessionData = await localForageService.getForage(FORAGE_KEY.SESSION_DATA) || {};
    let ads_dismissed = new Set(prevSessionData.ads_dismissed || []);
    let processedAds = new Set();
    globalAdViews.forEach(ad => {
      const closeButton = document.querySelector('.finalytics-close-alert');
      debuggerLog('[handleDismissedAds] closeButton ', closeButton);
      let divIdentifier;
      if (ad.div_id) {
        divIdentifier = `#${ad.div_id}`;
      } else if (ad.div_class) {
        divIdentifier = `.${ad.div_class}`;
      } else {
        divIdentifier = null;
      }
      const adElement = document.querySelector(divIdentifier);
      if (closeButton && !processedAds.has(ad.idAd)) {
        processedAds.add(ad.idAd);
        closeButton.addEventListener('click', async () => {
          debuggerLog('[handleDismissedAds] closeButton click');
          let dataFinAttr = closeButton.getAttribute('data-fin');
          let newPrevSessionData = { ...prevSessionData };
          const match = dataFinAttr.match(/\d+/);
          const dataFinId = match ? match[0] : null;
          if (adElement) {
            if (parseInt(dataFinId) === ad.idAd) {
              // adElement.remove();
              if (!ads_dismissed.has(ad.idAd)) {
                ads_dismissed.add(ad.idAd);
              }
              for (const uniqueAd of globalAdViews.filter(a => a.idAd === ad.idAd)) {
                let deactivateAdId = `idAd_${uniqueAd.idAd}idCamp_${uniqueAd.idCampaign}` || null;
                debuggerLog('[handleDismissedAds] Ad marked inactive in session', deactivateAdId);
                if (prevSessionData && prevSessionData.ads_viewed && prevSessionData.ads_viewed[deactivateAdId]) {
                  newPrevSessionData.ads_viewed[deactivateAdId].activeAd = false;
                  debuggerLog('[handleDismissedAds] Ad marked inactive in session', deactivateAdId);
                }
              }
              newPrevSessionData.ads_dismissed = Array.from(ads_dismissed);
              await localForageService.setForage(FORAGE_KEY.SESSION_DATA, newPrevSessionData);
              debuggerLog('[handleDismissedAds] Session data updated', newPrevSessionData);
            }
            window.onbeforeunload = () => {
              globalAdViews = [];
              localForageService.setForage(FORAGE_KEY.SESSION_DATA, newPrevSessionData);
            };
          }
          debuggerLog('[handleDismissedAds] Ad marked as inactive in session', ad.idAd);
          debuggerLog('[handleDismissedAds] Ad dismissal processed', ad.idAd);
        });
      }
    });
  }, 1000);
};

const normalizeString = (string) =>
  string && string.split(' ').join('').toLocaleLowerCase();


const normalizeText = (text) => {
  if (typeof text !== 'string') {
    debuggerLog('normalizeText: Input is not a string:', text);
    return '';
  }
  return text.replace(/[^\w]/g, '').toLowerCase().trim();
};

const findElementsInDocument = (doc, normalizedText) => {
  if (!doc || typeof doc.evaluate !== 'function') {
    console.error('Invalid document object provided:', doc);
    return [];
  }

  const xpath = `
    //a[contains(translate(normalize-space(translate(., 'ABCDEFGHIJKLMNOPQRSTUVWXYZ ', 'abcdefghijklmnopqrstuvwxyz')), ' ', ''), "${normalizedText}")]
    | //button[contains(translate(normalize-space(translate(., 'ABCDEFGHIJKLMNOPQRSTUVWXYZ ', 'abcdefghijklmnopqrstuvwxyz')), ' ', ''), "${normalizedText}")]
    | //input[@type='submit' and contains(translate(normalize-space(translate(@value, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ ', 'abcdefghijklmnopqrstuvwxyz')), ' ', ''), "${normalizedText}")]
  `;

  let matches = [];
  try {
    const elements = doc.evaluate(xpath, doc, null, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);
    let element = elements.iterateNext();

    while (element) {
      matches.push(element);
      element = elements.iterateNext();
    }
  } catch (error) {
    debuggerLog('Error evaluating XPath:', error);
  }

  if (matches.length === 0) {
    const normalizedSelector = normalizeText(normalizedText);
    matches = Array.from(doc.querySelectorAll('input[type="submit"]')).filter(el => {
      if (el && typeof el.value === 'string') {
        const normalizedValue = normalizeText(el.value);
        return normalizedValue.includes(normalizedSelector);
      }
      return false;
    });
  }

  return matches;
};

const findElementsInIframes = async (normalizedText) => {
  const iframes = document.querySelectorAll('iframe');
  // debuggerLog('[INFER:] Found iframes:', iframes.length);
  const allMatches = await Promise.all(Array.from(iframes).map(async (iframe, index) => {
    try {
      const iframeDoc = iframe.contentDocument;
      if (iframeDoc) {
        // debuggerLog(`[INFER:] Accessing iframe ${index}:`, iframeDoc);
        const elements = findElementsInDocument(iframeDoc, normalizedText);
        // debuggerLog(`[INFER:] Searching in iframe ${index}, elements found:`, elements);
        return elements;
      }
    } catch (error) {
      debuggerLog(`[INFER:] Could not access iframe ${index} due to cross-origin restrictions:`, error);
    }
    return [];
  }));

  return Array.isArray(allMatches) ? allMatches.flat() : [];
};

const searchForLoginButton = async (loginButtonText, permittedEntries, prevSession) => {
  // debuggerLog('[INFER:] searchForLoginButton loginButtonText:', loginButtonText);
  const normalizedLoginText = normalizeText(loginButtonText);
  debuggerLog('[INFER:] login text:', {normalizedLoginText, loginButtonText});

  let elements = findElementsInDocument(document, normalizedLoginText);
  // debuggerLog('[INFER:] Found elements in main document:', elements);

  if (document.readyState === 'complete') {
    await new Promise(resolve => setTimeout(resolve, 500));
    const iframeElements = await findElementsInIframes(normalizedLoginText);
    // debuggerLog('[INFER:] Searched in iframe, elements found:', iframeElements);
    elements = elements.concat(iframeElements);
  }

  const elementsWithListeners = new Set();
  elements.forEach(element => {
    const innerHTML = element?.innerHTML ? normalizeText(element.innerHTML) : '';
    const value = element?.value ? normalizeText(element.value) : '';
    const innerText = element?.innerText ? normalizeText(element.innerText) : '';

    if (
      element &&
      (permittedEntries.includes(normalizeText(innerHTML)) ||
       permittedEntries.includes(normalizeText(value)) ||
       permittedEntries.includes(normalizeText(innerText)))
    ) {
      if (!elementsWithListeners.has(element)) {
        debuggerLog('[INFER:] Adding click event listener to found element:', element);
        element.addEventListener('click', async () => {
          if (Object.entries(prevSession).length !== 0) {
            const memberData = { timestamp: Date.now() };
            prevSession.demographics.segments = {
              ...prevSession.demographics.segments,
              member: memberData,
            };
            await localForageService.setForage(FORAGE_KEY.SESSION_DATA, prevSession);
            localStorage.setItem('memberTimestamp', memberData.timestamp);
            window.onbeforeunload = () => {
              localForageService.setForage(FORAGE_KEY.SESSION_DATA, prevSession);
              localStorage.setItem('memberTimestamp', memberData.timestamp);
            };
          }
        });
        elementsWithListeners.add(element);
      }
    }
  });

  return elements;
};

const pixelComparator = async () => {
  debuggerLog('[comparator] init');
  handleDismissedAds();

  try {
    let counter = 0;
    const maxAttempts = 5;
    const interval = 1000;

    const poll = async (resolve, reject) => {
      counter++;
      debuggerLog('[comparator] counter increment', counter);
      const initialize = (await localForageService.getForage(FORAGE_KEY.INITIALIZE)) || {};
      debuggerLog('[comparator] initialize', initialize);
      
      if (counter >= maxAttempts) {
        debuggerLog('[comparator] counter expired', counter);
        reject('Polling timed out.');
        return;
      } 
      if (initialize && JSON.stringify(initialize) !== '{}' && initialize?.settings?.use_login_to_infer_member && window.document.readyState === 'complete') {
        debuggerLog('[comparator] initialize exists and document ready');
        
        const permittedEntries = ['signin', 'login', 'logon', 'signon'];
        const prevSession = (await localForageService.getForage(FORAGE_KEY.SESSION_DATA)) || {};
        const elements = await searchForLoginButton(initialize.settings.login_button_text, permittedEntries, prevSession);

        if (elements.length > 0) {
          debuggerLog('[comparator] Elements found, stopping further checks.');
          resolve();
          return;
        }
      }
      setTimeout(() => poll(resolve, reject), interval);
    };

    await new Promise(poll);
  } catch (e) {
    debuggerLog('[comparator] error', e);
  }
};

export {
  adsViewedAndExpirationOfAds,
  hydrateViweAd, mergeDeep, removeExpiredAds, removeIdsAndClassesDuplicates, pixelComparator
};

