/* eslint-disable no-console */
import { personalizeAllPages } from '../utils';
import {
  ApiUrlService,
  API_ENVIRONMENT,
  CacheStorageService,
  CACHES_KEY,
  DocumentBodyService,
  HttpsService,
  SuppressBodyService,
  LocalForageService,
  SUPRESS_CONTROL,
  TYPE_HTTPS,
  FORAGE_KEY
} from './index';
import {
  debuggerLog,
  debuggerLogError,
} from '../utils/debuggerLog';

const STATUS_CACHES = Object.freeze({
  NO_CACHE: 'no_cache',
  CACHE: 'cache',
});

export class ApiDirectoryService {
  cacheStorageService = new CacheStorageService();
  documentBodyService = new DocumentBodyService();
  apiUrlService = new ApiUrlService();
  localForageServe = new LocalForageService();
  endpoint = '';
  cu_code = '';
  getversion = {
    payload: {
      personalization_is_active: false,
      personalize_all_pages: false,
      version: null,
    },
  };

  constructor(code) {
    this.code = code;
  }

  get getGetversion() {
    return this.getversion;
  }

  async suppressBody() {
    // const cache = await this.getCache(
    //   `/getversion/?cu_id=${this.code}&session_init=1`
    // );
    //if (cache.payload.suppress_body)
    //  return localStorage.removeItem('suppress_body');
    //localStorage.setItem('suppress_body', SUPRESS_CONTROL.SUPPRESS);
    //this.documentBodyService.bodyVisible();
    await new SuppressBodyService().init();
  }

  setSessionStorage() {
    debuggerLog('this.getversion', this.getversion);
    let personalization_is_active = this.getversion?.payload?.personalization_is_active || this.getversion?.personalization_is_active || false;
    let version = this.getversion?.payload?.version || this.getversion?.version || false;
    sessionStorage.setItem(
      'personalizationIsActive',
      JSON.stringify(personalization_is_active)
    );
    sessionStorage.setItem(
      'personalizationVersion',
      JSON.stringify(version)
    );
  }

  async updateCache(data) {
    const newVersion = data.payload.version;
    if (newVersion === this.getversion.payload.version) return;
    await this.cacheStorageService.clearCaches();
    await this.setCache(
      `/getversion/?cu_id=${this.code}&session_init=1`,
      data
    );
    this.getversion = data;
  }
  async setCache(keyValue, data) {
    await this.cacheStorageService.setOneCaches(
      CACHES_KEY.GETVERSION,
      keyValue,
      data
    );
  }

  // async getCache(keyValue) {
  //   return await this.cacheStorageService.getCaches(
  //     CACHES_KEY.GETVERSION,
  //     keyValue
  //   );
  // }

  async getCacheStatus() {
    const existCache = await this.localForageServe.getForage(
      FORAGE_KEY.FIN_VERSION
    );

    debuggerLog('getCacheStatus', existCache);
    if (!existCache?.settings) {
      // this.endpoint = `getversion/?cu_id=${this.code}&session_init=1`;
      return STATUS_CACHES.NO_CACHE;
    }

    // this.endpoint = `getversion/?cu_id=${this.code}&session_init=1`;
    this.getversion = existCache?.settings;
    return STATUS_CACHES.CACHE;
  }

  urlVersion() {
    const queryParamApi = sessionStorage.getItem('api');
    const customerApi = this.apiUrlService.customerEnvironment();
    return customerApi
      ? `${customerApi}/${this.endpoint}`
      : `${API_ENVIRONMENT[`${queryParamApi}Q`] ||
      API_ENVIRONMENT[`${window.hostEnv || 'prod'}Q`]
      }/${this.endpoint}`;
  }

  async httpsVersion() {
    const ts_gv = Date.now();
    debuggerLog('ApiDirectory: getversion call start', Date.now() - window.ts_start);
    let count = 0;
    // localStorage.setItem(
    //   'getVersion',
    //   `/getversion/?cu_id=${this.code}&session_init=1`
    // );
    const result = await this.getCacheStatus();
    if (result === STATUS_CACHES.NO_CACHE) {
      window.firstPageView = true;
      debuggerLog('ApiDirectory: firstPageView EVER', window.firstPageView);
    }
    return new Promise((resolve, reject) => {
      const timer = setInterval(async () => {
        let gvFlag = false;
        if (window.finalytics) {
          gvFlag = !!window.injectSettings;
        }
        debuggerLog('ApiDirectory: gvFlag', gvFlag);
        // if (gvFlag) {
        clearInterval(timer);
        debuggerLog('ApiDirectory: injectSettings status', window.injectSettings);
        try {
          debuggerLog('ApiDirectory: processing start ms elapsed', Date.now() - ts_gv);
          debuggerLog(
            'ApiDirectory: suppressbody injectsettings firstvisitenabled',
            {
              suppressbody: window.suppress_body,
              injectSettings: window.injectSettings,
              firstVisitEnabled: !!window.firstVisitEnabled
            }
          );

          if (window.firstPageView && window.firstVisitEnabled === false) {
            window.skipAdsReplacement = true;
            window.finishedAdsReplacement = true;
            debuggerLog('ApiDirectory: firstPageView finishedAds', window.skipAdsReplacement);
          }
          debuggerLog('ApiDirectory: API end ms elapsed', Date.now() - ts_gv);
          debuggerLog('ApiDirectory: process data start ms elapsed', Date.now() - ts_gv);

          this.setSessionStorage();


          // debuggerLog('ApiDirectory: data.pages processed', data.pages);
          window.personalization_is_active = this.getversion?.personalization_is_active;
          window.personalize_all_pages = this.getversion?.personalize_all_pages;
          const urlPath = window.location.pathname || '';
          const allPages = window.personalize_all_pages;
          if (window.suppress_body === true && Array.isArray(data.pages)) {
            if ((!data.pages.includes(urlPath) && allPages === false)) {
              debuggerLog('ApiDirectory: finishedAdsReplacement set to true', Date.now() - ts_gv);
              window.finishedAdsReplacement = true;
            }
          }
          debuggerLog('ApiDirectory: process data end ms elapsed', Date.now() - ts_gv);
          debuggerLog('ApiDirectory: suppress body start ms elapsed', Date.now() - ts_gv);
          await this.suppressBody();

          debuggerLog('ApiDirectory: suppress body end ms elapsed', Date.now() - ts_gv);
          resolve();
        } catch (error) {
          debuggerLogError('ApiDirectory Error: ApiDirectoryService', error);
          // this.documentBodyService.bodyVisible();
          window.injectSettings = false;
          reject(error);
        }
        // } else if (count === 60) {
        //   clearInterval(timer);
        //   reject(debuggerLogError(`ApiDirectory: Condition for gvFlag not met within time limit (count=${count}).`));
        // }
        ++count;
      }, 50);
      debuggerLog('ts: API call end', Date.now() - window.ts_start);
    });

  }
}
