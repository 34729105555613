
export const octlDictionary = {
  '\\041': '!',
  '\\042': '"',
  '\\043': '#',
  '\\044': '$',
  '\\045': '%',
  '\\046': '&',
  '\\047': '\'',
  '\\050': '(',
  '\\051': ')',
  '\\052': '*',
  '\\053': '+',
  '\\054': ',',
  '\\055': '-',
  '\\056': '.',
  '\\057': '/',
  '\\060': '0',
  '\\071': '9',
  '\\072': ':',
  '\\073': ';',
  '\\074': '<',
  '\\075': '=',
  '\\076': '>',
  '\\077': '?',
};
  
  