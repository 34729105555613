/**
 * Debugger Tool
 * @param msg
 * @param ctx
 */

const getQueryParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name) || sessionStorage.getItem(name);
};

const enableDebug = getQueryParam('debug_js') === '1';
export const debuggerLog = (msg, ctx) => {
  if (window.finDebug || enableDebug) {
    // eslint-disable-next-line no-console
    console.log(msg, ctx || '');
  }
};
export const debuggerLogError = (msg, ctx) => {
  if (window.finDebug || enableDebug) {
    // eslint-disable-next-line no-console
    console.error(msg, ctx || '');
  }
};