import { debuggerLog } from '../utils/debuggerLog';
import { HttpsService, TYPE_HTTPS } from './HttpsService';

const TYPE_EVENT = Object.freeze({
  USER_INTERACTION: 'user_interaction',
  PAGE_LOAD: 'page_load',
});

const getFullUrl = (hostEnvSetting) => {
  let fullUrl = '';
  if (hostEnv === 'local') {
    fullUrl = 'http://127.0.0.1:8001/api/v1/analytics/';
  } else if (hostEnv === 'prod') {
    fullUrl = 'https://finalyticsdata.com/api/v1/analytics/';
  } else {
    fullUrl = `https://${hostEnvSetting}finalyticsdata.com/api/v1/analytics/`;
  }
  return fullUrl;
};

export class AnalyticsService {
  static instance;
  api = new HttpsService();
  dataPageLoad = [];
  userInteraction = [];
  cu_id = window.finalytics.clientId;
  hostEnvSetting = window.hostEnv || '';
  fullUrl = getFullUrl(this.hostEnvSetting);
  constructor() {
    return AnalyticsService.instance || (AnalyticsService.instance = this);
  }

  async sendToFin(data, isAnalytics = false) {
    debuggerLog('sendToFinApiCall', { isAnalytics });
    if (isAnalytics) {
      this.api.https({
        type: TYPE_HTTPS.POST,
        payload: {
          payload: {
            data,
          },
        },
        fullUrl: this.fullUrl,
        keepalive: true
      });
    } else {
      this.api.addToQueue({
        type: TYPE_HTTPS.POST,
        payload: {
          payload: {
            data,
          },
        },
        fullUrl: this.fullUrl,
        keepalive: true
      });
    }
  }

  sendPageLoad() {
    let doNotDupe = [];
    if (!this.dataPageLoad.length) return;
    const ad = this.dataPageLoad
      .map((ad) => {
        if (!window.finalytics.adsReplaced.includes(ad.id) || doNotDupe.indexOf(ad.id) > 0) return;
        doNotDupe.push(ad.id);
        return ad;
      })
      .filter(Boolean);
    //debuggerLog('[AnalyticsService] window.finalytics.adsReplaced adsR includes', window.finalytics.adsReplaced.includes(ad.id));
    //debuggerLog('[AnalyticsService] window.finalytics.adsReplaced donotdupe', doNotDupe.indexOf(ad.id));
    debuggerLog('[AnalyticsService] sendPageLoad window.finalytics.adsReplaced', ad);
    return Promise.all(ad.map((data) => this.sendToFin(data, true)));
    //debuggerLog('[AnalyticsService] window.finalytics.adsReplaced do not dupe', doNotDupe);
  }

  sendUserInteraction(data) {
    const sendUserInteraction = localStorage.getItem('sendUserInteraction');
    debuggerLog('[Analytics logged] sendUserInteraction localStorage before saving:', { sendUserInteraction });
    if (!sendUserInteraction) {
      localStorage.setItem('sendUserInteraction', JSON.stringify([data]));
    } else {
      const interactions = JSON.parse(sendUserInteraction);
      interactions.push(data);
      localStorage.setItem('sendUserInteraction', JSON.stringify(interactions));
    }

    // debuggerLog('[Analytics logged] sendUserInteraction localStorage after saving:', {
    //   updatedData: localStorage.getItem('sendUserInteraction')
    // });
  }

  getDataFinID = (str) => {
    return str.split('_').at(-1);
  };

  getUserInteractionByID(id) {
    return this.userInteraction.find((data) => data.id === id);
  }

  getCampaignType(id) {
    const campaign = window.finalytics.ads.find(
      (ad) => ad.id === id && ad.campaign && ad.campaign.campaign_type
    );
    return (
      (campaign && campaign.campaign && campaign.campaign.campaign_type) || null
    );
  }

  getGAid() {
    debuggerLog('ts: ga id start', Date.now() - window.ts_start);
    let ga_id = '';
    let ga_cookie = {};
    window.document.cookie.split(';').forEach(function (el) {
      let splitCookie = el.split('=');
      let key = splitCookie[0].trim();
      let value = splitCookie[1];
      ga_cookie[key] = value;
    });
    if ('_ga' in ga_cookie && ga_cookie['_ga']) {
      ga_id = ga_cookie['_ga'].substring(6);
    }
    //debuggerLog('[Finalytics Pixel] GA ID', ga_id);
    debuggerLog('ts: ga id end', Date.now() - window.ts_start);
    return ga_id;
  }

  sendToSaveSendUserInteraction() {
    const sendUserInteraction = localStorage.getItem('sendUserInteraction');
    debuggerLog('[Analytics logged] sendToSaveSendUserInteraction - retrieved from localStorage:', { sendUserInteraction });

    if (!sendUserInteraction) return;

    const unsentInteractions = JSON.parse(sendUserInteraction).filter(data => !data.sentImmediately);

    if (!unsentInteractions.length) return;

    localStorage.setItem('sendUserInteraction', JSON.stringify(unsentInteractions));
    localStorage.removeItem('sendUserInteraction');
    return Promise.all(unsentInteractions.map((data) => this.sendToFin(data, true)));
  }

  processAd(adDetails) {
    const { id, event, campaigns, div_id, div_class, div_tracking_label, core_product, name, algorithms,is_member, ...res } = adDetails;
    const idGtm = res['gtm.uniqueEventId'];
  
    return {
      event: event || 'Finalytics-JO',
      id,
      campaigns,
      cu_id: this.cu_id,
      campaign_type: this.getCampaignType(id),
      div_id,
      div_class,
      div_tracking_label,
      ['gtm.uniqueEventId']: idGtm,
      algorithms,
      page: window.location.pathname,
      core_product,
      name,
      is_member: is_member
    };
  }

  setDataPageLoadAndInteraction(tagsAdsReplaced) {
    debuggerLog('[AnalyticsService] setDataPageLoadAndInteraction tagsAdsReplaced', { tagsAdsReplaced });
    const memberTimestamp = localStorage.getItem('memberTimestamp') || '';
    let productsOwnedProcessed = [];
    try {
      productsOwnedProcessed = JSON.parse(localStorage.getItem('productsOwnedProcessed')) || [];
    } catch (e) {
      console.error('Error parsing productsOwnedProcessed from localStorage:', e);
    }

    const isMember = !!memberTimestamp || productsOwnedProcessed.length > 0;
    debuggerLog('[AnalyticsService] windowDataLayer', { dataLayer: window.dataLayer  });
    const adsData = window.dataLayer?.filter((ga) => ga.id) || tagsAdsReplaced || [];
    debuggerLog('[AnalyticsService] adsData', { adsData });

    adsData.forEach((ad) => {
      const adDetails = ad.adRes || ad;
      const baseObje = {
        ...this.processAd(adDetails),
        ga_id: this.getGAid(),
        is_member: isMember 
      };
      debuggerLog('[AnalyticsService] baseObje', { baseObje });
  
      this.dataPageLoad.push({
        ...baseObje,
        event_type: TYPE_EVENT.PAGE_LOAD,
        env: this.hostEnvSetting,
      });
  
      this.userInteraction.push({
        ...baseObje,
        event_type: TYPE_EVENT.USER_INTERACTION,
        env: this.hostEnvSetting,
      });
    });
  }

  cleanLinkLabel(item) {
    return item.textContent.replace(/\s+/g, ' ').trim();
}

  async handleNonPersonalizedBanners(flatPages, pages, isHrefInPages, allIds, allClasses, validationControl, clientId) {
    debuggerLog('[NONPersonalizedBanner] init');
    let currPages = flatPages(pages);
    const currentPage = window.location.pathname || '';
    const pageDetails = isHrefInPages(currentPage, currPages);
    if (!pageDetails) {
      debuggerLog('[NONPersonalizedBanner]: Current pathname is not in currPages, skipping.');
      return;
    }
    const productName = pageDetails?.url || '';
    [...document.querySelectorAll('a, button')].forEach((item) => {
      item.addEventListener('click', async (event) => {
        // event.preventDefault();
        const hrefValue = item.getAttribute('data-fin') || null;
        // let classes = (window.divObj && window.divObj.classes) || [];
        let classes = allClasses.length > 0 && allClasses || (window.divObj && window.divObj.classes) || [];
        // let ids = (window.divObj && window.divObj.ids) || [];
        let ids = allIds.length > 0 && allIds || (window.divObj && window.divObj.ids) || [];
        let formattedClasses = classes
          .filter(cls => cls)
          .map(cls => {
            const parts = cls.split('.');
            if (parts.length > 1 && /^[a-zA-Z]+$/.test(parts[0])) {
              return `${parts[0]}.${parts.slice(1).join('.')}`;
            } else {
              return cls.startsWith('.') ? cls : `.${cls.trim().replace(/\s+/g, '.')}`;
            }
          });

        let formattedIds = ids
          .filter(id => id)
          .map(id => id.startsWith('#') ? id : `#${id.trim()}`);

        let combinedSelectors = formattedClasses.concat(formattedIds).join(',');
        const matchedElement = combinedSelectors && item.closest(combinedSelectors) || '';
        let matchedType = '';
        let matchedValue = '';
        debuggerLog('[NONPersonalizedBanner]: formattedClasses:', formattedClasses);
        debuggerLog('[NONPersonalizedBanner]: formattedIds:', formattedIds);
        debuggerLog('[NONPersonalizedBanner]: matchedElement:', matchedElement);

        if (matchedElement) {
          if (formattedClasses.some(cls => matchedElement.matches(cls))) {
            matchedType = 'div_class';
            matchedValue = matchedElement.className;
          } else if (formattedIds.some(id => matchedElement.matches(id))) {
            matchedType = 'div_id';
            matchedValue = matchedElement.id;
          }
        }
        // debuggerLog('[NONPersonalizedBanner]: matchedType:', matchedType);

        if (!hrefValue && matchedElement) {
          const hostEnv = window.hostEnv || 'prod';
          const cuId = clientId;
          const gaId = this.getGAid();
          const link_label = this.cleanLinkLabel(item);
          const eventData = {
            'event': 'Finalytics-JO',
            'cu_id': cuId,
            'ga_id': gaId,
            'host_env': hostEnv,
            'event_type': 'user_interaction_default',
            'core_product': productName,
            'timestamp': validationControl('time'),
            'page': currentPage,
            'tracking_type': matchedValue,
            'link_label': link_label
          };

          debuggerLog('[NONPersonalizedBanner]: eventData:', eventData);
          await this.sendToFin(eventData, true);
        }
        // window.location = item.href;
      });
    });
  }
  
  addEventListenerGA(id) {
    const getCTAs = document.querySelectorAll(`[data-fin="ad:id__${id}"]`);

    getCTAs.forEach((ad) => {
      if (!ad.hasListenerAttached) {
        ad.hasListenerAttached = true;
        ad.addEventListener('click', () => {
          const data = this.getUserInteractionByID(id);
          const link_label = this.cleanLinkLabel(ad);
          const updatedData = { ...data, link_label: link_label, sentImmediately: true };
          if (window.dataLayer) {
            window.dataLayer.push({
              ...data,
              env: this.hostEnvSetting,
              user_interaction_element: ad.tagName,
              interaction_element_text: link_label,
            });
          }
          this.sendUserInteraction(updatedData);
          this.sendToFin(updatedData, true);
        });
      }
    });
  }

  analyticsAdsReplaced() {
    let doNotDupe = [];
    if (window && window.finalytics && window.finalytics.adsReplaced &&  window.finalytics.adsReplaced.length > 0) {
      debuggerLog('[AnalyticsService] analyticsAdsReplaced: adsreplaced before loop', window.finalytics.adsReplaced);
      window.finalytics.ads.forEach(
        ({ id, div_id, core_product, geo, ...ad }) => {
          //debuggerLog('[AnalyticsService] analyticsAdsReplaced: ad.id', id);
          //debuggerLog('[AnalyticsService] analyticsAdsReplaced: doNotDupe', doNotDupe);
          //debuggerLog('[AnalyticsService] analyticsAdsReplaced: !disable', !ad.disable_tracking);
          //debuggerLog('[AnalyticsService] analyticsAdsReplaced: ad in adsreplaced', window.finalytics.adsReplaced.indexOf(id));
          //debuggerLog('[AnalyticsService] analyticsAdsReplaced: ad in doNotDupe', doNotDupe.indexOf(id));
          if (!ad.disable_tracking && window.finalytics.adsReplaced.indexOf(id) >= 0 && doNotDupe.indexOf(id) < 0) {
            debuggerLog('[AnalyticsService] analyticsAdsReplaced: send', id);
            let adToPush = {
              event: 'Finalytics-JO',
              event_type: TYPE_EVENT.PAGE_LOAD,
              id,
              div_id,
              page: window.location.href,
              segments: [],
              core_product,
              cu_id: this.cu_id,
              env: this.hostEnvSetting,
              replacements: {
                geo: {
                  span: geo || '',
                },
              },
              ...ad,
            };
            adToPush.ad_html = '';
            //debuggerLog('[AnalyticsService] analyticsAdsReplaced: adToPush', adToPush);
            if (window.dataLayer) {
              window.dataLayer.push(adToPush);
            }
            doNotDupe.push(id);
            //this.addEventListenerGA(id);
          }
        }
      );
    }
    debuggerLog('[AnalyticsService] analyticsAdsReplaced: sent2', doNotDupe);
    //debuggerLog('[AnalyticsService] analyticsAdsReplaced: sent3', window.finalytics.adsReplaced);
    debuggerLog('[AnalyticsService] analyticsAdsReplaced: datalayer', window.dataLayer);
  }

  analyticsInit() {
    debuggerLog('[AnalyticsService] : analyticsInit in fun window.finalytics', window.finalytics);
    if (window && window.finalytics && window.finalytics.ads && window.finalytics.ads.length) {
      debuggerLog('[AnalyticsService] : analyticsInit window.finalytics.ads', window.finalytics);
      window.finalytics.ads.forEach(
        ({ id, div_id, core_product, geo, ...ad }) => {
          if (!ad.disable_tracking) {
            //window.dataLayer.push({
            //  event: 'Finalytics-JO',
            //  event_type: TYPE_EVENT.PAGE_LOAD,
            //  id,
            //  div_id,
            //  page: window.location.href,
            //  segments: [],
            //  core_product,
            //  cu_id: this.cu_id,
            //  env: this.hostEnvSetting,
            //  replacements: {
            //    geo: {
            //      span: geo || '',
            //    },
            //  },
            //  ...ad,
            //});
            this.addEventListenerGA(id);
          }
        }
      );
    }
  }

  async executeAdsReplaced(tagsAdsReplaced) {
    debuggerLog('[AnalyticsService] tagsAdsReplaced:', {tagsAdsReplaced});
    this.analyticsAdsReplaced();
    //this.analyticsInit();
    this.setDataPageLoadAndInteraction(tagsAdsReplaced);
    this.sendToSaveSendUserInteraction();
    this.sendPageLoad();
  }

  async execute() {
    this.analyticsInit();
    //this.setDataPageLoadAndInteraction();
    //this.sendToSaveSendUserInteraction();
    //this.sendPageLoad();
    debuggerLog('[AnalyticsService]', this);
  }

  executeAll(tagsAdsReplaced) {
    this.execute();
    this.executeAdsReplaced(tagsAdsReplaced);
  }
}
