export const alertHtml = () => `<div style="display:flex; justify-content: row; flex-direction: row-reverse; align-items: flex-end;"> 
<button
  id="3435"
  style="
    text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    visibility: visible;
    box-sizing: inherit;
    background: rgb(255, 255, 255);
    overflow: visible;
    text-transform: none;
    display: inline-block;
    vertical-align: middle;
    font-family: inherit;
    appearance: none;
    transition: background-color 0.25s ease-out 0s, color 0.25s ease-out 0s;
    font-size: 35px;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
    color: rgb(61, 61, 61);
  "
  onmouseover='this.style.color="#c1590c"'
  onmouseout='this.style.color="#3d3d3d"'
>&times</button>
</div>`;
